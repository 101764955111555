import React, { useEffect } from 'react';
import gsap, { SplitText } from 'gsap/all';

gsap.registerPlugin(SplitText);

export const useSplitText = (ref, options = { type: "words, chars" }) => {
    let target = null;
    if(typeof ref === 'string') {
        target = document.querySelector(ref);
    }
    if(typeof ref === 'object') {
        target = ref.current;
    }
    const tl = gsap.timeline();
    let splitText = null;

    useEffect(() => {
        if (target) {
            const split = new SplitText(target, options);
            splitText = split.chars;
            gsap.set(ref, { perspective: 400, transformStyle: "preserve-3d" });
            tl.from(splitText, { duration: 1, opacity: 0, y: 80, rotationX: 180, transformOrigin: "0% 50% -50", ease: "back", stagger: 0.1 }, 0.1)
        }
    }, [ref, options, tl]);

    return splitText;
}