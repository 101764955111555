import { createSlice } from '@reduxjs/toolkit'
import { from } from 'rxjs'
import { map } from 'rxjs/operators'
 
const initialState = {
    
    state: 'initial',
    hasEntered: false,
}

export const headerSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        setEntered: (state, action) => {
            state.hasEntered = action.payload
        },
        setState: (state, action) => {
            state.state = action.payload
        }
      }
  })
  
 