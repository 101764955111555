import { createSlice } from '@reduxjs/toolkit'

const brand = {
  CLIENT: "Grappa",
  DRUG: "some drug",
  CODE: "xxxx - xxxx - xxxx",
  dr: "Dr Mease",
  drFull: "Dr Philip Mease",

}

const theme = {
  pink: '#DF0E63',
  darkGrey: "#414042",
  darkGreyAlt: "#2D2927",
  darkPurple: '#592D5F'
}
const pageTypes = {
  HOME: "home",
  GRAPPA_OVERVIEW: "grappa-overview",
  PERIPHERAL_ARTHRITIS: "peripheral-arthritis",
  AXIAL_SYMPTOMS: "axial-symptoms",
  ENTHESITIS: "enthesitis",
  DACTYLITIS: "dactylitis",
  PLAQUE_PSORIASIS: "plaque-psoriasis",
  NAIL_PSORIASIS: "nail-psoriasis",
}


const initialState = {
  use3d: false,
  brand,
  theme,
  pageTypes,
  loaded: false,
}

export const coreSlice = createSlice({
  name: 'core',
  initialState,
  reducers: {
    setLoaded: (state) => {
      state.loaded = true
    },
    toggle3d: (state) => {
      state.use3d = !state.use3d
    }
  }
})

// const { 
//     toggleDebugMode,
//     toggleIsi,
//     setIsiEntered,
//     setIsiDragging
//    } = coreSlice.actions

//   export { coreSlice }
