import { useCore } from "../../core";
import { HotspotText } from "./HotspotText";
import { Plus } from "../Plus";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

export const HotspotButton = ({ hotspot }) => {
    const navigate = useNavigate();
    const coreState = useCore();
    const { assets, hotspotActions } = coreState;

    const handleClick = () => {

        navigate(`/${hotspot.id}`);
    }

    return (
        <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
         className="hotspot-button flex" 
         onClick={handleClick}
         >
            <span className="inline-flex">
                <img className="hotspot-icon" src={assets[hotspot.pill.img].url} alt="" />
                <HotspotText hotspot={hotspot} />
            </span>
            <Plus />
        </motion.div>

    )
}