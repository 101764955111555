import { motion } from "framer-motion";

export const HoverScale = ({ children, className, style }) => {
    return (
        <motion.div
            className={className}
            style={style}
            whileHover={{ scale: 1.075 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 300 }}
        >
            {children}
        </motion.div>
    )
}