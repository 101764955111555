export const DesktopHeaderContent = (props) => {
    return (
        <>

            <p className="isi-subtitle isi-enter-animation" style={{ marginTop: 0 }}>
                IMPORTANT SAFETY INFORMATION
            </p>

                <div className={`flex columns-wrapper ${props.isIsiOpen && "reverse-columns"}`}>
                    
                    <div className="col">
                        <p className={`isi-subtitle isi-enter-animation`}>CONTRAINDICATIONS</p>
                        <p className="isi-enter-animation">
                            COSENTYX is contraindicated in patients with a previous serious
                            hypersensitivity reaction to secukinumab or to any of the
                            excipients in COSENTYX. Cases of anaphylaxis have been reported
                            during treatment with COSENTYX.
                        </p>
                    </div>
                    <div className="col">
                        <p className="isi-subtitle isi-enter-animation">INDICATIONS</p>
                        <p className="isi-enter-animation">
                            COSENTYX<sup>&reg;</sup> (secukinumab) is indicated for the
                            treatment of moderate to severe plaque psoriasis in patients 6
                            years and older who are candidates for systemic therapy or
                            phototherapy.
                        </p>
                    </div>
                </div>

        </>
    );
}