import React, { useRef, useEffect, useCallback } from 'react';
import gsap, { Draggable, ScrollTrigger } from 'gsap/all';
import { IsiContent } from './IsiContent';
import { IsiHeader } from './IsiHeader';
import { useHeight } from '../../hooks';
import { useResponsive } from 'ahooks';
import { Close } from "../Close";
gsap.registerPlugin(Draggable, ScrollTrigger);
export const Isi = ({ pageName, bottom, bottomIsiInView }) => {

    const drawerRef = useRef(null);
    const backdropRef = useRef(null);
    const headerRef = useRef(null);
    const contentRef = useRef(null);
    let { height: drawerHeightVal } = useHeight(drawerRef);
    let { height: headerHeightVal } = useHeight(headerRef);
    let { height: contentHeightVal } = useHeight(contentRef);
    const drawerHeight = useRef(drawerHeightVal);
    const headerHeight = useRef(headerHeightVal);
    const contentHeight = useRef(contentHeightVal)
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const responsive = useResponsive();
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(null);

    useEffect(() => {
        console.log(isDrawerOpen)
    }, [isDrawerOpen])

    const getSnapPoint = useCallback(() => {
        try {
            if (!drawerHeight.current || !headerHeight.current) {
                console.warn('getSnapPoint', 'drawerHeight', drawerHeight.current, 'headerHeight', headerHeight.current)
                const d = document.querySelector('.isi');
                const h = document.querySelector('.isi-header');
                if (d && h) {
                    drawerHeight.current = d.getBoundingClientRect().height
                    headerHeight.current = h.getBoundingClientRect().height
                }
            }
            const openY = '40%';
            const closedY = window.innerHeight - headerHeight.current;
            console.log('getSnapPoint')
            return {
                open: openY,
                closed: closedY,
            }
        } catch (e) {
            console.error('getSnapPoint', e)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowWidth, drawerHeight.current, headerHeight.current, responsive]);
    const toggleDrawer = useCallback(() => {
        const drawer = drawerRef.current;
        const backdrop = backdropRef.current;
        const targetY = isDrawerOpen ? getSnapPoint().closed : getSnapPoint().open;
        gsap.to(drawer, {
            y: targetY,
            duration: 0.3,
            onComplete: () => {
                if (targetY === windowHeight - headerHeight.current) {
                    backdrop.style.pointerEvents = 'auto';
                }
            },
        });
        gsap.to(backdrop, {
            opacity: targetY === windowHeight - headerHeight.current ? 0 : 1,
            duration: 0.3,
            onStart: () => {
                if (targetY !== windowHeight - headerHeight.current) {
                    backdrop.style.visibility = 'visible';
                }
            },
            onComplete: () => {
                if (targetY === windowHeight - headerHeight.current) {
                    backdrop.style.visibility = 'hidden';
                }
            },
        });
        if (isDrawerOpen) {
            drawerRef.current.scrollTop = 0;
        }
        setIsDrawerOpen(prev => !prev);
    }, [headerHeight, getSnapPoint, isDrawerOpen, windowHeight]);
    const handleDrawerClick = useCallback((e) => {
        if (bottom) {
            return;
        }
        toggleDrawer();
    }, [toggleDrawer, bottom]);

    const setIsi = useCallback(() => {
        if (bottom) {
            return;
        }
        if (headerHeight.current === 0) {
            headerHeight.current = document.querySelector('.isi-header').getBoundingClientRect().height;
        }
        const drawer = drawerRef.current;
        gsap.set(drawer, { y: windowHeight });
        gsap.set('#isi-spacer', { height: headerHeight.current })
        if (backdropRef.current) {
            backdropRef.current.style.visibility = 'hidden';
        }
        gsap.to(drawer, { y: getSnapPoint().closed, opacity: 1, duration: 0 });

        if (drawerRef.current) {
        drawerRef.current.scrollTop = 0;
        }
        setIsDrawerOpen(false);
    }, [getSnapPoint, windowHeight, bottom]);

    useEffect(() => {
        const onResize = () => {
            const newDrawerHeight = drawerRef.current.getBoundingClientRect().height;
            const newHeaderHeight = headerRef.current.getBoundingClientRect().height;
            const newContentHeight = contentRef.current.getBoundingClientRect().height;
            if (newDrawerHeight !== drawerHeight.current) {
                drawerHeight.current = newDrawerHeight;
                setIsi();
            }
            if (newHeaderHeight !== headerHeight.current) {
                headerHeight.current = newHeaderHeight;
                setIsi();
            }
            if (newContentHeight !== contentHeight.current) {
                contentHeight.current = newContentHeight;
                setIsi();
            }
            setIsi();
        }
        window.addEventListener('resize', () => onResize)
        return () => {
            setIsi();
            window.removeEventListener('resize', () => onResize)
        }
    }, [drawerHeight, headerHeight, contentHeight, setIsi])
    useEffect(() => {
        setIsi();
    }, [setIsi]);
    useEffect(() => {
        const anchorTags = document.querySelectorAll('.isi a');
        anchorTags.forEach((aTag) => {
            aTag.addEventListener('click', (e) => {
                e.stopPropagation();
            });
        });
        return () => {
            anchorTags.forEach((aTag) => {
                aTag.removeEventListener('click', (e) => {
                    e.stopPropagation();
                });
            });
        };
    })


    console.log('isDrawerOpen', isDrawerOpen)
    console.log('bottom', bottom)
    console.log('inView', bottomIsiInView)
    return (
        <div style={{ position: 'relative' }}>
            <div className="backdrop" ref={backdropRef} onClick={handleDrawerClick} />
            <div
                className={`drawer isi ${isDrawerOpen ? "open" : "closed"} ${bottom ? "bottom" : ""}`}
                ref={drawerRef}
                onClick={handleDrawerClick}
            >

                <Close className="isi-enter-animation" toggle={false} isOpen={isDrawerOpen} />

                {(!bottomIsiInView && !isDrawerOpen) && <IsiHeader ref={headerRef} isOpen={isDrawerOpen} bottom={bottom} bottomIsiInView={bottomIsiInView} />}
                <IsiContent ref={contentRef} isOpen={isDrawerOpen} responsive={responsive} bottom={bottom} bottomIsiInView={bottomIsiInView} />
            </div>
            <div style={{ position: 'absolute', top: 0, right: 0, height: '100%', width: '50%', backgroundColor: 'white' }} />
        </div>

    );
};