import React, { useEffect, useRef } from 'react'
import { useCore } from "../../core";
import { useKeyPress } from 'ahooks';
import { useLocation } from 'react-router-dom';
import Stats from 'stats.js';
import gsap, { Draggable } from 'gsap/all';
import { GSD } from './GSD';
let stats
gsap.registerPlugin(Draggable)
export const Debugger = () => {
  const location = useLocation();
  const coreState = useCore();
  const { uiActions, mouse, hotspots, hotspotActions, core, coreActions } = coreState
  const { toggleDebugMode } = coreActions;
  const { debugMode } = core
  const { isi } = coreState;
  const { activeHotspot } = hotspots

  // const [{position, velocity, prevPosition, isDown, isDragging,isMoving }, set] = useControls({
  //   position: {
  //     value: {x: mouse.x, y: mouse.y},
  //     onChange: (position) => setMousePosition(position.x, position.y)
  //   },  
  // velocity: {vx:mouse.vx, vy:mouse.vy},
  // prevPosition: {lx: mouse.lx,ly: mouse.ly},
  // isDown: mouse.isDown,
  // isDragging: mouse.isDragging,
  // isMoving: mouse.isMoving,
  // })



  // const activeHotspot = useControls({
  //   activeHotSpot: {
  //     value: activeHotSpot
  //   }
  // })


  /********************************************
  * SET DEBUG STATE IN URL TO SHOW DEBUG STATE 						    
  ********************************************/
  useEffect(() => {
    debugMode === true ? (window.location.hash = 'debug') : (window.location.hash = '')
  }, [debugMode, location.pathname])

  useEffect(() => {
    stats = new Stats();


    Draggable.create('.debug', {
      type: 'x,y',
      // bounds: 'document',
      edgeResistance: 0.65,
      inertia: true,
    })

    stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
    if (!debugMode) return
    document.body.appendChild(stats.dom);

    gsap.ticker.add(() => {
      stats.update();
    });




    return () => {
      stats.dom.remove();
    }
  }, [debugMode])

 
  /********************************************
   * KEYPRESS EVENT TO TOGGLE DEBUG STATE 						    
   * CTRL + D TOGGLE DEBUG STATE 						    
   * ******************************************/
  useKeyPress(['ctrl.d'], () => {

    toggleDebugMode();
  });


  return (
    <>

      <div className='debug' style={{ display: debugMode ? 'block' : 'none', background: 'white', borderRadius: 12, padding: 12, color: 'black', zIndex: 999999, minHeight: 'auto', width: 200, position: 'fixed', top: 200, left: 50 }}>
        <h4>HOTSPOT: {activeHotspot}</h4>
        <h4>ISI Header Height: {isi.headerHeight}</h4>
        <h4>ISI HEIGHT: {isi.height}</h4>
        <h4>ISI: Is Open: {isi.isOpen ? "true" : "false"}</h4>
      </div>
      {debugMode && <GSD debugMode={debugMode} />}
    </>
  )

}