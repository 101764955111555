import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useCore } from "../core";
import gsap from 'gsap/all';

export const CirclePage = ({ children }) => {
    const page = useRef(null);
    const coreState = useCore();
    
    return (
        <div className="circle-page" ref={page}>
            <div className="circle-page__content">
                {children}
            </div>
        </div>
    )
}