import React, { useEffect, useLayoutEffect, useRef } from "react";
import gsap, { ScrollTrigger, ScrollSmoother } from "gsap/all";
import { useThrottle } from 'ahooks'
import { useControls } from 'leva'
import { useCore } from "../core";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

export const Scroller = ({ children }) => {
    const coreState = useCore()
    const { setScrollX, setScrollY, lockBodyScroll, unlockBodyScroll } = coreState.uiActions
    const { theme } = coreState.core
    const trackRef = useRef(null);
    const scrollSmoother = useRef(null);
    const barRef = useRef(null);
    const heightTo = useRef(null);
    const created = useRef(false)
    // const isiRef = useRef()
    heightTo.current = gsap.quickTo(barRef.current, "height", { ease: "power2.out", duration: 0.1 });




    useEffect(() => {
        // if (scrollSmoother.current && scrollSmoother.current.kill) {
        //     scrollSmoother.current.kill()
        // }


        const h = window.innerWidth <= 1100 ? window.innerHeight : window.innerHeight * .85
        const isiHeight = document.querySelector(".isi-header").getBoundingClientRect().height;
        // const page = document.querySelector(".page");
        gsap.set(trackRef.current, { height: h - isiHeight });
        const trackHeight = trackRef.current.clientHeight - isiHeight;
        const barHeight = barRef.current.clientHeight;
        barRef.current.style.backgroundColor = theme.pink;
        scrollSmoother.current = ScrollSmoother.create({

            smooth: true,
            effects: true,
            smoothTouch: 0.1,
            normalizeScroll: true,
            onUpdate: function (self) {
                // setScrollX(self.x);
                // setScrollY(self.y);
                const height = self.progress * (trackHeight - barHeight);
                heightTo.current(height);
                // isiRef.current.style.transform = `translateY(${self.y * -1000}px)`
            },
        });




      
    });

    return (

        <>
            <div id="smooth-wrapper">
                <div id="smooth-content">

                    {children}
                    <div id="scroll-trigger"></div>
                </div>


            </div>


            <div className="scrollTrack" ref={trackRef}>
                <div className="scrollBar" ref={barRef}></div>
            </div>
        </>
    )
}