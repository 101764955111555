import { configureStore } from '@reduxjs/toolkit'

import {
    backdropSlice,
    coreSlice,
 
    headerSlice,
    assetsSlice,
    mouseSlice,
    uiSlice,
    isiSlice,
    hotspotsSlice,
    debugModeSlice,
    animationSlice

} from '../slices'


export const store = configureStore({
    reducer: {
        assets: assetsSlice.reducer,
        backdrop: backdropSlice.reducer,
        core: coreSlice.reducer,
   
        hotspots: hotspotsSlice.reducer,
        header: headerSlice.reducer,
        mouse: mouseSlice.reducer,
        ui: uiSlice.reducer,
        isi: isiSlice.reducer,
        animation: animationSlice.reducer,
        debugMode: debugModeSlice.reducer
    },
})

