import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import gsap from 'gsap';
import { HotspotIcon } from './HotspotIcon';
import { HotspotText } from './HotspotText';
import { HotspotGrid } from './HotspotGrid';
import { HotspotButton } from './HotspotButton';
import { motion } from "framer-motion";
import * as PIXI from 'pixi.js';
const Hotspot = ({ hotspot, icon, pageName, inline = false }) => {
    const hotspotRef = useRef(null);
    const iconRef = useRef(null);
    const textRef = useRef(null);
    const canvas = useRef(null);
    const navigate = useNavigate();
    const tl = gsap.timeline();
    const hotspotClick = () => {
        navigate(`/${hotspot.id}`);
    };

    const handleMouseEnter = () => {
        tl.to([iconRef.current, textRef.current], { scale: 1.1, duration: .5, stagger: .075, ease: "elastic.out" })
    }

    const handleMouseLeave = () => {
        tl.to([iconRef.current, textRef.current], { scale: 1, duration: .5, stagger: .075, ease: "elastic.in" })
        //tl.reverse();
    }

    // useEffect(() => {
    //     if (!magnetic) return;

    //     const magnet = textRef.current;
    //     let hover = false;
    //     const offsetHoverMax = 0.7;
    //     const offsetHoverMin = 0.5;

    //     const handleMouseMove = (e) => {
    //         const hoverArea = hover ? offsetHoverMax : offsetHoverMin;
    //         const cursor = {
    //             x: e.clientX,
    //             y: e.clientY,
    //         };

    //         const width = magnet.getBoundingClientRect().width;
    //         const height = magnet.getBoundingClientRect().height;

    //         const rect = magnet.getBoundingClientRect();
    //         const elPos = {
    //             x: rect.left + width / 2,
    //             y: rect.top + height / 2,
    //         };

    //         const x = cursor.x - elPos.x;
    //         const y = cursor.y - elPos.y;
    //         const dist = Math.sqrt(x * x + y * y);
    //         let mutHover = false;

    //         if (dist < width * hoverArea) {
    //             mutHover = true;
    //             if (!hover) {
    //                 hover = true;
    //             }
    //             onHover(x, y);
    //         }

    //         if (!mutHover && hover) {
    //             onLeave();
    //             hover = false;
    //         }
    //     };

    //     const onHover = (x, y) => {
    //         gsap.to(magnet, 0.4, {
    //             x: x * 0.8,
    //             y: y * 0.8,
    //             z: 200,
    //             rotation: x * 0.05,
    //             ease: Power2.easeOut,
    //         });
    //     };

    //     const onLeave = () => {
    //         gsap.to(magnet, 0.7, {
    //             x: 0,
    //             y: 0,
    //             z: 0,
    //             scale: 1,
    //             rotation: 0,
    //             ease: Elastic.easeOut.config(1.2, 0.4),
    //         });
    //     };

    //     window.addEventListener('mousemove', handleMouseMove);

    //     return () => {
    //         window.removeEventListener('mousemove', handleMouseMove);
    //     };
    // }, [magnetic]);

    // useEffect(() => {

    // const updateCanvasSize = () => {
    //     const hotspotBounds = hotspotRef.current.getBoundingClientRect();
    //     app.renderer.resize(hotspotBounds.width, hotspotBounds.height);
    // };
    // const app = new PIXI.Application({
    //     // resolution: window.devicePixelRatio,
    //     // view: canvas.current,
    //     transparent: true,
    //     autoResize: true,
    //     resizeTo: hotspotRef.current,
    //     // backgroundColor: 'transparent',
    //     // alpha: true
    // });

    // const c = hotspotRef.current;

    // // create graphics
    // const graphics = new PIXI.Graphics();
    // const line = new PIXI.Container();

    // const glowFilter = new GlowFilter({
    //     distance: 15,
    //     outerStrength: 10,
    //     color: 0xd60e63,
    // });



    // line.filters = [glowFilter];





    // updateCanvasSize();








    // const drawLine = () => {
    //     if (!iconRef.current || !textRef.current) return;

    //     const iconBounds = iconRef.current.getBoundingClientRect();
    //     const textBounds = textRef.current.getBoundingClientRect();
    //     const hotspotBounds = hotspotRef.current.getBoundingClientRect();

    //     const iconCenter = {
    //         x: iconBounds.left + iconBounds.width / 2 - hotspotBounds.left,
    //         y: iconBounds.top + iconBounds.height / 2 - hotspotBounds.top,
    //     };

    //     const textCenter = {
    //         x: textBounds.left + textBounds.width / 2 - hotspotBounds.left,
    //         y: textBounds.top + textBounds.height / 2 - hotspotBounds.top,
    //     };



    //     graphics.clear();
    //     line.removeChildren();
    //     graphics.lineStyle(1, 0xd60e63);
    //     graphics.moveTo(iconCenter.x, iconCenter.y);
    //     graphics.lineTo(textCenter.x, textCenter.y);
    //     graphics.closePath();

    //     line.addChild(graphics);
    //     line.filters = [glowFilter];


    //     // add line container to stage
    //     app.stage.addChild(line);
    //     app.stage.addChild(graphics);

    //     if(c)
    //         c.appendChild(app.view);

    // };

    // // Add the drawLine function to the PIXI ticker
    // app.ticker.add(drawLine);
    // window.addEventListener('resize', () => {
    //     updateCanvasSize();
    //     drawLine();
    // });
    // return () => {
    //     window.removeEventListener('resize', () => {
    //         updateCanvasSize();
    //         drawLine();
    //     });
    //     if(lineRef.current.children.length > 0)
    //         lineRef.current.remove(app.view);
    //     if (c && c.children.length > 0)
    //         c.removeChild('canvas');
    // };
    // }, []);

    // ... Add your magnetic effect useEffect hook here

    let dynamicLandingStyle = hotspot.landingStyle;
    if ('landingStyleVideo' in hotspot && pageName !== 'home') {
        dynamicLandingStyle = hotspot.landingStyleVideo;
    }

    return (
        <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 300}}
            ref={hotspotRef}
            onClick={hotspotClick}
            className={`hotspot hotspot-${hotspot.textPosition} ${hotspot.id}`}
            style={dynamicLandingStyle}
        >
            <canvas ref={canvas} className="hotspot-line" />

            <HotspotIcon
                ref={iconRef}
                icon={icon}
                id={hotspot.id}
                hotspot={hotspot}
                pageName={pageName}
            />
            <HotspotText
                ref={textRef}
                hotspot={hotspot}
            />
        </motion.div>
    );
};


export { Hotspot, HotspotIcon, HotspotText, HotspotGrid, HotspotButton }
