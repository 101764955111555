import { useState, useEffect, useCallback } from 'react'
import { useRafState, useDebounceFn, useThrottle } from 'ahooks'
import { set } from 'lodash'

export const useHeight = (myRef) => {
  const [height, setHeight] = useRafState(0)
  const [width, setWidth] = useRafState(0)
  const handleResize = () => {
    setHeight(myRef.current.clientHeight)
    setWidth(myRef.current.clientWidth)
    
  };

  useEffect(() => {
    console.log(height)
  }, [height])
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    // window.addEventListener('load', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
      // window.removeEventListener('load', handleResize)
    }
  }, [handleResize])


  return height
}