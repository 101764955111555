import React, { useState, useEffect } from "react";
import { useCore } from "../core";
import { useRef } from "react";
import { gsap } from "gsap";

export const Close = ({ toggle, className, isOpen, callback }) => {
    const icon = useRef(null);
    const text = useRef(null);
    const coreState = useCore();

    if( isOpen === undefined) {
        console.error("Close component requires isOpen prop");
        return;
    }
    
    const { assets } = coreState;
    const { ContractIcon } = assets;
    const tl = gsap.timeline();
    const handleMouseEnter = () => {
        console.log('hover')
        gsap.to([icon.current, text.current], { scale: 1.1, duration: 0.3, ease: "expo.out" });
    }

    const handleMouseLeave = () => {
        console.log('hover out')
        gsap.to([icon.current, text.current], { scale: 1, duration: 0.3, ease: "expo.out" })
    }

    const handleClick = () => {
        callback();
    }

    const handlePress = () => {
        gsap.to([icon.current, text.current], { scale: .9, duration: 0.3, ease: "expo.out" });
    }

    const handlePressRevert = () => {
        gsap.to([icon.current, text.current], { scale: 1, duration: 0.3, ease: "expo.out" });
    }

    return (
        <div className={`close`}>
            <div
                className={`close-button ${className}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onMouseDown={handlePress}
                onMouseUp={handlePressRevert}
                onClick={handleClick}
            >
                <span ref={text}>{ isOpen ? 'COLLAPSE' : 'EXPAND'}</span>
                <img ref={icon} src={isOpen ? 'https://mkg-public-assets.s3.amazonaws.com/grappa-guidelines/Desktop_%402x_PNG/contractIcon2%402x.png' : 'https://mkg-public-assets.s3.amazonaws.com/grappa-guidelines/Desktop_%402x_PNG/expandIcon1%402x.png'} alt="" className="contract-icon" />
            </div>
        </div>
    );
}