import React, { useRef, useLayoutEffect, useEffect } from "react";
import { useCore } from "../core";
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import gsap, { SplitText } from 'gsap/all';

gsap.registerPlugin(SplitText);

export const BackArrow = ({ animation, pageName}) => {
    const backArrow = useRef(null);
    const coreState = useCore();
    const navigate = useNavigate();
    const { assets } = coreState;
    const { BackArrow } = assets;
    const anim = useRef(null);

    useEffect(() => {
        const split = new SplitText('.back-arrow p' ,{ type: 'chars', charsClass: 'char' });
    }, [])
    const handleMouseEnter = () => {
        anim.current = gsap.to('.char', {  scale: 1.01, duration: .01, stagger: .05, ease: 'elastic.out' });
    }
    const handleMouseLeave = () => {
        anim.current.reverse()
    }
    return (
        <motion.div
        variants={animation[pageName]}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: .9 }}
            onClick={() => {
                navigate("/");
            }}
            ref={backArrow}
            className="back-arrow"
        >
            <motion.img className="char" src={BackArrow.url} alt="" />
            <motion.p>BACK</motion.p>
        </motion.div>

    )

}