import { createSlice } from '@reduxjs/toolkit'
 
const videoPlaquePsoriasis = 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4';
const videoGrappaOverview = 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4';
const videoPeripheralArthritis = 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4';
const videoAxialSymptoms = 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4';
const videoDactylitis = 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4';
const videoEnthesitis = 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4';
const videoNailPsoriasis = 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4';
const BaseLink = 'https://mkg-public-assets.s3.amazonaws.com/grappa-guidelines';

// LOGO
const Logo = 'https://cosentyx.s3.amazonaws.com/cosentyx-grappa/logo.svg';
const HeadShot = 'https://cosentyx.s3.amazonaws.com/cosentyx-grappa/headshot.png';
const Divider = 'https://cosentyx.s3.amazonaws.com/cosentyx-grappa/divide.png';
const NovartisLogo = 'https://va-email-assets.s3.amazonaws.com/090723a/novartis-logo.svg';
const Curve = '../../assets/img/curve.png';
const BackArrow = 'https://cosentyx.s3.amazonaws.com/cosentyx-grappa/back.svg';
const Plus = 'https://cosentyx.s3.amazonaws.com/cosentyx-grappa/plus.svg';
const BlueExit = 'https://va-email-assets.s3.amazonaws.com/grappa/exit.svg';
const Line = 'https://cosentyx.s3.amazonaws.com/cosentyx-grappa/hotspot-line.svg';

//ICONS
const ContractIcon = BaseLink + '/Desktop_%402x_PNG/contractIcon2%402x.png';
const ExpandArrowIcon = BaseLink + '/Desktop_%402x_PNG/expandIcon1@2x.png';
const PinkHotspot = BaseLink + '/Desktop_%402x_PNG/hotspot%402x.png';
const GreyHotspot = BaseLink + '/Desktop_%402x_PNG/overviewHotspot1%402x.png';
const ExpandButton = BaseLink + '/Mobile_%402x_PNG/expandBtn%402x.png';
const EndPointsButton = BaseLink + '/Desktop_%402x_PNG/endpointsIcon%402x.png';

//WOMAN IMAGES
const Woman = 'img/woman.svg';
const smallAllClinicalDomains = BaseLink + '/Desktop_%402x_PNG/smallAllClinicalDomains%402x.png';
const smallAxialSymptoms = BaseLink + '/Desktop_%402x_PNG/smallAxialSymptoms%402x.png';
const smallDactylitis = BaseLink + '/Desktop_%402x_PNG/smallDactylitis%402x.png';
const smallEnthesitis = BaseLink + '/Desktop_%402x_PNG/smallEnthesitis%402x.png';
const smallNailPsoriasis = BaseLink + '/Desktop_%402x_PNG/smallNailPsoriasis%402x.png';
const smallPeripheralArthritis = BaseLink + '/Desktop_%402x_PNG/smallPeripheralArthritis%402x.png';
const smallPlaquePsoriasis = BaseLink + '/Desktop_%402x_PNG/smallPlaquePsoriasis%402x.png';

//PILL IMAGES
const pillAxialSymptoms = BaseLink + '/Mobile_%402x_PNG/axialsymptomsArt%402x.png';
const pillDactylitis = BaseLink + '/Mobile_%402x_PNG/dactylitisArt%402x.png';
const pillEnthesitis = BaseLink + '/Mobile_%402x_PNG/enthesitisArt%402x.png';
const pillNailPsoriasis = BaseLink + '/Mobile_%402x_PNG/nailpsoriasisArt%402x.png';
const pillPeripheralArthritis = BaseLink + '/Mobile_%402x_PNG/peripheralarthritisArt%402x.png';
const pillPlaquePsoriasis = BaseLink + '/Mobile_%402x_PNG/plaquepsoriasisArt%402x.png';

//VIDEO POSTER IMAGES
const posterGrappaOverview = BaseLink + '/Desktop_%402x_PNG/grappaoverviewScrn@2x.png';
const posterAxialSymptoms = BaseLink + '/Desktop_%402x_PNG/axialdiseaseScrn@2x.png';
const posterDactylitis = BaseLink + '/Desktop_%402x_PNG/dactylitisScrn@2x.png';
const posterEnthesitis = BaseLink + '/Desktop_%402x_PNG/enthesitisScrn@2x.png';
const posterNailPsoriasis = BaseLink + '/Desktop_%402x_PNG/nailpsoriasisScrn@2x.png';
const posterPeripheralArthritis = BaseLink + '/Desktop_%402x_PNG/peripheralarthritisScrn@2x.png';
const posterPlaquePsoriasis = BaseLink + '/Desktop_%402x_PNG/plaquepsoriasisScrn@2x.png';

const assets = [
    {
        name: 'Logo',
        type: "image",
        url: Logo,
    },
    {
        name: 'LineHotspot',
        type: "image",
        url: Line,
    },
    {
        name: 'BlueExit',
        type: "image",
        url: BlueExit,
    },
    {
        name: 'Plus',
        type: "image",
        url: Plus,
    },
    {
        name: 'BackArrow',
        type: "image",
        url: BackArrow,
    },
    {
        name: 'NovartisLogo',
        type: "image",
        url: NovartisLogo,
    },
    {
        name: 'Curve',
        type: "image",
        url: Curve,
    },
    {
        name: 'HeadShot',
        type: "image",
        url: HeadShot,
    },
    {
        name: 'Divider',
        type: "image",
        url: Divider,
    },
    {
        name: 'ContractIcon',
        type: "image",
        url: ContractIcon,
    },
    {
        name: 'ExpandArrowIcon',
        type: "image",
        url: ExpandArrowIcon,
    },
    {
        name: 'PinkHotspot',
        type: "image",
        url: PinkHotspot,
    },
    {
        name: 'GreyHotspot',
        type: "image",
        url: GreyHotspot,
    },
    {
        name: 'ExpandButton',
        type: "image",
        url: ExpandButton,
    },
    {
        name: 'EndPointsButton',
        type: "image",
        url: EndPointsButton,
    },
    {
        name: 'Woman',
        type: "image",
        url: Woman,
    },
    {
        name: 'smallAllClinicalDomains',
        type: "image",
        url: smallAllClinicalDomains,
    },
    {
        name: 'smallAxialSymptoms',
        type: "image",
        url: smallAxialSymptoms,
    },
    {
        name: 'smallDactylitis',
        type: "image",
        url: smallDactylitis,
    },
    {
        name: 'smallEnthesitis',
        type: "image",
        url: smallEnthesitis,
    },
    {
        name: 'smallNailPsoriasis',
        type: "image",
        url: smallNailPsoriasis,
    },
    {
        name: 'smallPeripheralArthritis',
        type: "image",
        url: smallPeripheralArthritis,
    },
    {
        name: 'smallPlaquePsoriasis',
        type: "image",
        url: smallPlaquePsoriasis,
    },
    {
        name: 'pillAxialSymptoms',
        type: "image",
        url: pillAxialSymptoms,
    },
    {
        name: 'pillDactylitis',
        type: "image",
        url: pillDactylitis,
    },
    {
        name: 'pillEnthesitis',
        type: "image",
        url: pillEnthesitis,
    },
    {
        name: 'pillNailPsoriasis',
        type: "image",
        url: pillNailPsoriasis,
    },
    {
        name: 'pillPeripheralArthritis',
        type: "image",
        url: pillPeripheralArthritis,
    },
    {
        name: 'pillPlaquePsoriasis',
        type: "image",
        url: pillPlaquePsoriasis,
    },
    {
        name: 'posterGrappaOverview',
        type: "image",
        url: posterGrappaOverview,
    },
    {
        name: 'posterAxialSymptoms',
        type: "image",
        url: posterAxialSymptoms,
    },
    {
        name: 'posterDactylitis',
        type: "image",
        url: posterDactylitis,
    },
    {
        name: 'posterEnthesitis',
        type: "image",
        url: posterEnthesitis,
    },
    {
        name: 'posterNailPsoriasis',
        type: "image",
        url: posterNailPsoriasis,
    },
    {
        name: 'posterPeripheralArthritis',
        type: "image",
        url: posterPeripheralArthritis,
    },
    {
        name: 'posterPlaquePsoriasis',
        type: "image",
        url: posterPlaquePsoriasis,
    },
    {
        name: 'videoGrappaOverview',
        type: "video",
        src: videoGrappaOverview,
    },
    {
        name: 'videoAxialSymptoms',
        type: "video",
        src: videoAxialSymptoms,
    }, {
        name: 'videoDactylitis',
        type: "video",
        src: videoDactylitis,
    }, {
        name: 'videoEnthesitis',
        type: "video",
        src: videoEnthesitis,
    }, {
        name: 'videoNailPsoriasis',
        type: "video",
        src: videoNailPsoriasis,
    }, {
        name: 'videoPeripheralArthritis',
        type: "video",
        src: videoPeripheralArthritis,
    }, {
        name: 'videoPlaquePsoriasis',
        type: "video",
        src: videoPlaquePsoriasis,
    }
]
const coreAssets = {}
 assets.map((asset, index) => {
    coreAssets[asset.name] = asset
    if(asset.type === 'image') {
        coreAssets[asset.name] = {
            url: asset.url,
            // img: new Image(),
        }
        // coreAssets[asset.name].img.src = asset.url
    }
    if(asset.type === 'video') {
        coreAssets[asset.name] = {
            src: asset.src,
            // video: document.createElement('video'),
        }
        // coreAssets[asset.name].video = document.createElement('video')
        // coreAssets[asset.name].video.src = asset.src
    }

})

const initialState = coreAssets
window.coreAssets = coreAssets
export const assetsSlice = createSlice({
    name: 'assets',
    initialState,
    reducers: { }
  })
  
 
