import React, { useEffect, useRef } from "react";
import { HotspotIcon } from "./Hotspot/HotspotIcon";
import { motion } from "framer-motion";
import { useCore } from "../core/";
import { useNavigate } from "react-router-dom";

export const Headshot = () => {
    const containerRef = useRef(null);
    const navigate = useNavigate();
    const coreState = useCore();
    const { assets, core, hotspots } = coreState;
    const grappaOverview = hotspots.hotspots.filter(hotspot => hotspot.id === "grappa-overview");
    const { brand } = core;
    const { drFull } = brand;

    return (
        <div ref={containerRef} className="headshot-row headshot-text flex enter-animation">
            <div className="inline-flex desktop">
                <p className="headshot-text">Select a hotspot
                    <motion.span
                     className="flex"
                     >
                    <HotspotIcon
                     hotspot={grappaOverview}
                      className="hotspot-icon-inline"
                      
                       noAnimation
                        icon="pink"
                        pageName={"home"} />
                    </motion.span> 
                    to learn more from {drFull}</p>
            </div>
            <img src={assets.HeadShot.url} alt="" className="mease-headshot" />
            <p className="headshot-text mobile">Select a topic to learn more from {drFull}</p>
        </div>
    );
}