import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Rodal from 'rodal';
import { useCore } from '../../core';
import { useLockBodyScroll } from '../../hooks';
import { Close } from '../Close';
import 'rodal/lib/rodal.css';
import gsap, { ScrollTrigger, ScrollSmoother, Draggable } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger, ScrollSmoother, Draggable);

export const GuidelineModal = () => {
  const locked = useLockBodyScroll();
  const coreState = useCore();
  const { assets, ui, uiActions } = coreState;
  const { guideLineModalIsOpen } = ui;
  const { toggleGuidelineModal } = uiActions


  const close = () => {
    toggleGuidelineModal();
    document.body.style.overflow = 'auto';
  };


  return (
    <Rodal
      visible={guideLineModalIsOpen}
      onClose={close}
      onOpen
      closeMaskOnClick
      customStyles={{ overflowY: 'auto' }}
      animation={'fade'}
      className={'guideline  flama-book'}
    >
      {/* <div id="guideline-smooth-wrapper">
        <div id="guideline-smooth-content"> */}
      <Close toggle={true} isOpen={guideLineModalIsOpen} callback={close} />

      <div>
        <p>
          <b>COSENTYX<sup>&reg;</sup> (secukinumab) primary end points and trial results</b>
        </p>
        <p>
          In the pivotal trial, FUTURE 2, in a mixed population (two-thirds biologic-naive and
          one-third anti-TNF-&alpha; inadequate responders) in patients with PsA<sup>2,3</sup>:
        </p>

        <table className='mb-5' style={{ marginBottom: 0 }}>
          <tbody>
            <tr style={{ verticalAlign: 'top' }}>
              <td className="guideline-bullet">&bull;</td>
              <td>
                ACR20 responses at Week 16 (NRI) were 57%, 60%, and 18% for COSENTYX 300 mg (n=100),
                150 mg (n=100), and placebo (n=98), respectively (<i>P</i>&lt;0.0001)
              </td>
            </tr>
            <tr style={{ verticalAlign: 'top' }}>
              <td className="guideline-bullet">&bull;</td>
              <td>
                ACR20 responses at Week 24 <b>(primary end point)</b> (NRI) were 54%, 51%, and 15% for
                COSENTYX 300 mg (n=100), 150 mg (n=100), and placebo (n=98), respectively
                (<i>P</i>&lt;0.0001)
              </td>
            </tr>
            <tr style={{ verticalAlign: 'top' }}>
              <td  className="guideline-bullet">&bull;</td>
              <td>
                Skin lesions improved in patients with coexistent PsO who received COSENTYX (n=99) compared with placebo (n=44) as measured by the PASI<sup>2,3</sup>
              </td>
            </tr>
            <tr style={{ verticalAlign: 'top' }}>
              <td className="guideline-bullet">&bull;</td>
              <td>
                Improvements in enthesitis and dactylitis scores were observed in the COSENTYX group compared to placebo at week 24<sup>3</sup>

              </td>
            </tr>
            <tr style={{ verticalAlign: 'top' }}>
              <td></td>
              <td>
                In FUTURE 5, in a mixed population in patients with PsA<sup>4</sup>:
              </td>
            </tr>

            <tr style={{ verticalAlign: 'top' }}>
              <td className="guideline-bullet">&bull;</td>
              <td>
                ACR20 responses at Week 16 (<b>primary end point</b>) (NRI) were 63%, 56%, and 27% for
                COSENTYX 300 mg (n=222), 150 mg (n=220), and placebo (n=332), respectively
                (<i>P</i>&lt;0.0001)
              </td>
            </tr>
            <tr style={{ verticalAlign: 'top' }}>
              <td className="guideline-bullet">&bull;</td>
              <td>
                Complete resolution (LEI=0) at Week 16 (secondary end point) in patients with enthesitis at baseline (NRI) was observed in 56%, 55%, and 35% for COSENTYX 300 mg (n=140), 150 mg (n=141), and placebo (n=192), respectively
              </td>
            </tr>
            <tr style={{ verticalAlign: 'top' }}>
              <td className="guideline-bullet">&bull;</td>
              <td>
                Complete resolution (LDI=0) at Week 16 (secondary end point) in patients with dactylitis at baseline (NRI) was observed in 66%, 57%, and 32% for COSENTYX 300 mg (n=82), 150 mg (n=80), and placebo (n=124), respectively
              </td>
            </tr>

            <tr style={{ verticalAlign: 'top' }}>
              <td className="guideline-bullet">&bull;</td>
              <td>
                Patients who achieved PASI 90 at Week 16 (secondary end point) (NRI) were 54%, 37%, and 9% for COSENTYX 300 mg (n=110), 150 mg (n=125), and placebo (n=162), respectively
              </td>
            </tr>

            <tr style={{ verticalAlign: 'top' }}>
              <td className="guideline-bullet">&bull;</td>
              <td>
                Reduction in nail disease (mNAPSI as observed) response rates* at Week 16 were 51%, 53%, and 11% for COSENTYX 300 mg (n=125), 150 mg (n=128), and placebo (n=203)<sup>†</sup>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          In MAXIMISE, whose study design, patient population, and dosing regimen are consistent with those of FUTURE 2<sup>5</sup>:
        </p>
        <table>
          <tbody>
            <tr style={{ verticalAlign: 'top' }}>
              <td></td>
            </tr>
            <tr style={{ verticalAlign: 'top' }}>
              <td className="guideline-bullet">&bull;</td>
              <td>
                63% experienced relief on COSENTYX 300 mg at Week 12 vs 31% with placebo, as measured by ASAS20 (<i>P</i>&lt;0.0001) (n=164) (multiple imputation)
              </td>
            </tr>
            <tr style={{ verticalAlign: 'top' }}>
              <td className="guideline-bullet">&bull;</td>
              <td>
                In FUTURE 2, 20% of patients had spondylitis with peripheral arthritis<sup>3</sup>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td style={{ position: 'relative', 'top': -5  }}>*</td>
              <td>
                Nail data in PsA were consistent with those in the dedicated nail PsO study, TRANSFIGURE. Improvement from baseline NAPSI at Week 16 (<b>primary end point</b>) (repeated measures analysis) was 46.1% for
                COSENTYX 300 mg (n=63) and 11.7% for placebo (n=56) (<i>P</i>&lt;0.0001).<sup>6</sup>
              </td>
            </tr>

            <tr>
              <td><sup>†</sup></td>
              <td>At baseline, mNAPSI scores were 18.0 and 17.8 in the 300-mg and 150-mg arms, respectively. At Week 16, mNAPSI scores were 8.8 and 8.4 in the 300-mg and 150-mg arms, respectively.<sup>4</sup></td>
            </tr>
          </tbody>
        </table>

        <p><b>COSENTYX study designs</b></p>
        <p >
          <b>FUTURE 2</b> was a multicenter, randomized, double-blind, placebo-controlled trial that
          evaluated 397 adult patients with active PsA (&ge;3 swollen and &ge;3 tender joints)
          despite use of NSAIDs, corticosteroids, or DMARDs. Patients had a diagnosis for &ge;5
          years and received subcutaneous COSENTYX 75 mg (n=99), 150 mg (n=100), 300 mg (n=100), or
          placebo (n=98) at Weeks 0, 1, 2, 3, and 4, followed by the same dose every 4 weeks
          thereafter. Patients who received placebo were rerandomized to COSENTYX 150 mg or 300 mg
          every 4 weeks at Week 16 or Week 24 based on responder status. Primary end point was the
          percentage of patients with ACR20 response at Week 24. Study population was mixed:
          two-thirds of patients were biologic-naive and one-third were anti&mdash;TNF&ndash;&alpha;
          inadequate responders.
          <sup>2,3,6</sup>
        </p>
        <p className='mt-5'>
          <b>FUTURE 5</b> was a phase 3, multicenter, randomized, double-blind, placebo-controlled trial
          that evaluated 996 adult patients with active PsA. Patients were randomized to receive
          subcutaneous COSENTYX 150 mg without load (n=222), 150 mg with load (n=220), 300 mg with
          load (n=222), or placebo (n=332). Patients who received placebo were rerandomized to
          receive COSENTYX 150 mg or 300 mg every 4 weeks, based on responder status at Week 16
          (nonresponders) or Week 24 (responders). Primary end point was the percentage of patients
          with ACR20 response at Week 16. Secondary end points included change in mTSS at Week 24
          from baseline. Study population was mixed: more than two-thirds of patients were
          biologic-naive and less than one-third were anti-TNF-&alpha; inadequate
          responders (individual patients could have been exposed to up to 3 TNF&alpha;
          inhibitors).<sup>4,7</sup>
        </p>
        <p className='mt-5'>
          <b>MAXIMISE</b> was a randomized, double-blind,
          placebo-controlled, multicenter, 52-week study that evaluated
          498 patients with active PsA and axial skeleton involvement
          (dened by BASDAI score&ge;4, spinal pain VAS 40 [0 to
          100-mm scale]) who had inadequate response to at least 2
          NSAIDs for at least 4 weeks. Patients were randomized to
          receive subcutaneous COSENTYX 150 mg (n=165), COSENTYX
          300 mg (n=167), or placebo (n=166) at Weeks 0, 1, 2, 3, 4, and
          8. Primary end point was the proportion of patients with
          ASAS20 response at Week 12. After Week 12, patients who
          were placed in the placebo group at baseline were
          rerandomized to active treatment with COSENTYX 150 mg or
          300 mg, administered every 4 weeks from Week 12 to Week 52
          (last dose on Week 48) but remained blinded to dose.<sup>8</sup>
        </p>
        <p style={{ marginTop: 10}}>
          <b>TRANSFIGURE</b> was a double-blind, randomized,
          placebo-controlled study examining the safety and efficacy of
          COSENTYX in patients with moderate to severe nail PsO.
          Patients were randomized to COSENTYX 300 mg (n=66),
          COSENTYX 150 mg (n=67), or placebo (n=65). All patients
          were adults with moderate to severe PsO (PASI score &ge;12 and
          BSA &ge;10%) and signicant nail involvement (fingernail NAPSI
          score of &ge;16 and &ge;4 fingernails involved) who were candidates
          for systemic therapy. Primary end point: NAPSI assessment at
          Week 16. Secondary end points included NAPSI response over
          time up to Week 132.<sup>9</sup>
        </p>
        <p  style={{ marginTop: 10}}>
          ACR, American College of Rheumatology;  BASDAI, Bath Ankylosing Spondylitis Disease Activity Index; DMARD, disease-modifying antirheumatic drug; LDI, Leeds Dactylitis Index; LEI, Leeds Enthesitis Index; mNAPSI, modified Nail Psoriasis Severity Index; mTSS,
          modified Total Sharp Score;  NAPSI, Nail Psoriasis Severity Index; PsA, psoriatic arthritis; PsO, plaque psoriasis; TNF&alpha;, tumor necrosis factor-&alpha;;VAS, visual analog scale.
        </p>
        <p style={{ marginTop: 10}}>
          <b>References: 1.</b> Coates LC, Soriano ER, Corp N, et al. Group for Research and Assessment of
          Psoriasis and Psoriatic Arthritis (GRAPPA): updated treatment recommendations for
          psoriatic arthritis 2021. <i>Nat Rev Rheumatol.</i> 2022;18(8):465-479. <b>2.</b> Data on file.
          CAIN457F2312 Clinical Study Report. Novartis Pharmaceuticals Corp; October 2014. <b>3.</b> Cosentyx [prescribing information]. East Hanover, NJ: Novartis Pharmaceuticals Corp. <b>4.</b> Data on file. CAIN457F2342 Clinical Study Report Interim Analysis-Week 24. Novartis
          Pharmaceuticals Corp; November 2017. <b>5.</b> Baraliakos X, Gossec L, Pournara E, et al. Secukinumab in patients with psoriatic arthritis and axial manifestations: results from the double-blind, randomised, phase 3 MAXIMISE trial. <i>Ann Rheum Dis.</i> 2021;80(5):582-590. <b>6.</b> Data on file. CAIN457F2312 Interim Study Report.
          Novartis Pharmaceuticals Corp; November 2015. <b>7.</b> Mease P, van der Heijde D, Landew&eacute;
          R, et al. Secukinumab improves active psoriatic arthritis symptoms and inhibits
          radiographic progression: primary results from the randomised, double-blind, phase III
          FUTURE 5 study. <i>Ann Rheum Dis.</i> 2018;77(6):890-897. <b>8.</b> Data on file. CAIN457F3302 (MAXIMISE) Data Analysis Report. Novartis Pharmaceuticals Corp; June 2016.<b> 9.</b> Data on file. CAIN457A2313 Clinical Study Report. Novartis Pharmaceuticals Corp; October 2017. 
        </p>
      </div>

    </Rodal>
  );
};
