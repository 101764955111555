import React, { useCallback, useEffect, useMemo, useRef } from 'react'
// import {
//     trackGtmEvent,
// } from './Analytics'


export default function GrappaVideo({
    videoSrc,
    height = 350,
    width = 620,
    posterSrc,
    id
}) {
    const videoRef = useRef(null)



    // This is gonna be our percent buckets (25%-75%)
    var divisor = 25;
    // We're going to save our player's status on this object.
    var videos_status = useMemo(() => {
        return {}
    }, [])

    // This is the function that is gonna handle the event sent by the player listeners
    const eventHandler = useCallback((e) => {
        // Let's check which event has been sent by the player
        let dataLayer = window.dataLayer || [];

        switch (e.type) {
            // This event type is sent every time the player updates its current time,
            // We're using it for the % of the video played.
            case 'timeupdate':
                // Let's save the current player's video time in our status object
                videos_status[e.target.id].current = Math.round(e.target.currentTime);
                // We just want to send the percent events once
                var pct = Math.floor(100 * videos_status[e.target.id].current / e.target.duration);
                for (var j in videos_status[e.target.id]._progress_markers) {
                    if (pct >= j && j > videos_status[e.target.id].greatest_marker) {
                        videos_status[e.target.id].greatest_marker = j;
                    }
                }
                // current bucket hasn't been already sent to GA? Let's push it to GTM
                if (videos_status[e.target.id].greatest_marker && !videos_status[e.target.id]._progress_markers[videos_status[e.target.id].greatest_marker]) {
                    videos_status[e.target.id]._progress_markers[videos_status[e.target.id].greatest_marker] = true;
                    dataLayer.push({
                        'event': 'html5_video',
                        'video_status': 'progress',
                        'video_provider': 'generic html5 video player',
                        'video_percent': videos_status[e.target.id].greatest_marker,
                        // We are sanitizing the current video src string and getting just the video name part
                        'video_title': decodeURIComponent(e.target.currentSrc.split('/')[e.target.currentSrc.split('/').length - 1])
                    });
                }
                break;
            // This event is fired when the user clicks on the play button
            case 'play':
                dataLayer.push({
                    'event': 'html5_video',
                    'video_status': 'play',
                    'video_provider': 'generic html5 video player',
                    'video_percent': videos_status[e.target.id].greatest_marker,
                    'video_title': decodeURIComponent(e.target.currentSrc.split('/')[e.target.currentSrc.split('/').length - 1])
                });
                break;
            // This event is fired when the user clicks on the pause button
            case 'pause':
                if (videos_status[e.target.id].greatest_marker < 75) {
                    dataLayer.push({
                        'event': 'html5_video',
                        'video_status': 'pause',
                        'video_provider': 'generic html5 video player',
                        'video_percent': videos_status[e.target.id].greatest_marker,
                        'video_title': decodeURIComponent(e.target.currentSrc.split('/')[e.target.currentSrc.split('/').length - 1])
                    });
                }
                break;
            // If the user ends playing the video, a Finish video will be pushed (This equals to % played = 100)
            case 'ended':
                dataLayer.push({
                    'event': 'html5_video',
                    'video_status': 'complete',
                    'video_provider': 'generic html5 video player',
                    'video_percent': 100,
                    'video_title': decodeURIComponent(e.target.currentSrc.split('/')[e.target.currentSrc.split('/').length - 1])
                });
                break;
            default:
                break;
        }
    }, [videos_status])

    useEffect(() => {
        // TagManager.dataLayer({
        //         dataLayer: {
        //             test: 'test yay from ryan'
        //         },
        //         dataLayerName: 'PageDataLayer'
        // })
        // We need to configure the listeners
        // Let's grab all the "video" objects on the current page
        var videos = document.getElementsByTagName('video');
        for (var i = 0; i < videos.length; i++) {
            // In order to have some id to reference in our status object, we are adding an id to the video objects
            // that don't have an id attribute.
            var videoTagId;
            if (!videos[i].getAttribute('id')) {
                // Generate a random alphanumeric string to use as the id
                videoTagId = 'html5_video_' + Math.random().toString(36).slice(2);
                videos[i].setAttribute('id', videoTagId);
            } // Current video has already an id attribute, then let's use it
            else {
                videoTagId = videos[i].getAttribute('id');
            }
            // Video Status Object declaration
            videos_status[videoTagId] = {};
            // We'll save the highest percent mark played by the user in the current video.
            videos_status[videoTagId].greatest_marker = 0;
            // Let's set the progress markers, so we can know afterwards which ones have been already sent.
            videos_status[videoTagId]._progress_markers = {};
            for (var j = 0; j < 100; j++) {
                videos_status[videoTagId].progress_point = divisor * Math.floor(j / divisor);
                videos_status[videoTagId]._progress_markers[videos_status[videoTagId].progress_point] = false;
            }
            // On page DOM, all player's currentTime is 0
            videos_status[videoTagId].current = 0;
            // Now we're setting the event listeners.
            videos[i].addEventListener("play", eventHandler, false);
            videos[i].addEventListener("pause", eventHandler, false);
            videos[i].addEventListener("ended", eventHandler, false);
            videos[i].addEventListener("timeupdate", eventHandler, false);
            debugger
        }


    }, [divisor, eventHandler, videoRef, videoSrc, videos_status])


    // const handlePlay = useCallback((e) => {

    //     trackGtmEvent({
    //         dataLayer: {
    //             event: 'html5_video_play',
    //             video_start_time: e.target.currentTime,
    //             video_name: e.target.currentSrc.split('/')[e.target.currentSrc.split('/').length - 1]
    //         },
    //         dataLayerName: 'GrappaVideoPlay'
    //     })

    // }, [])

    return (
        <video
            id={id}
            ref={videoRef}
            width={width}
            height={height}
            poster={posterSrc}
            controls
            playsInline
            muted
            // play events
            // onPlay={(e) => {
            //     eventHandler(e)
            // }}
        // onPause={(e) => {
        //     console.log('onPause')
        //     eventHandler(e)
        // }}
        // onTimeUpdate={(e) => {
        //     console.log('onTimeUpdate')
        //     eventHandler(e)
        // }}
        // onSeeking={(e) => {
        //     console.log('onSeeking')
        //     eventHandler(e)
        // }}
        // onSeeked={(e) => {
        //     console.log('onSeeked')
        //     eventHandler(e)
        // }}
        // onEnded={(e) => {
        //     console.log('onEnded')
        //     eventHandler(e)
        // }}
        // // error events
        // onError={(e) => {
        //     console.log('onError')
        //     eventHandler(e)
        // }}
        >
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    )
}
