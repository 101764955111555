 
import Rodal from 'rodal';
import { useCore } from '../../core';
import { Close } from '../Close';
import 'rodal/lib/rodal.css';

export const TrialModal = () => {
  const coreState = useCore();
  const { assets, ui, uiActions } = coreState;
  const { trialModalIsOpen } = ui;
  const { toggleTrialModal, lockBody } = uiActions

  const handleClick = () => {
    toggleTrialModal(false);
    document.body.style.overflow = 'auto';
  }
 

  return (
    <Rodal
    customStyles={{ overflowY: 'auto' }}

      visible={trialModalIsOpen}

      onClose={() => {
        toggleTrialModal(false);
        document.body.style.overflow = 'auto';

      }}
      animation={'fade'}
      className={'modal trial-modal modal-container'}
    >
      <Close toggle={true} isOpen={trialModalIsOpen} callback={handleClick} />
      <div className="trial modal-wrapper flama-book">
      <p>

The updated GRAPPA 2021 Guidelines expand on the overarching principles put forth in its 
2015 recommendations and are based on systematic literature reviews of data published 
from 2013 to 2020 taken from Medline, Embase, and the Cochrane Library, including 
conference presentations from 2017 to 2020. The recommendations used a Grading of 
Recommendations, Assessment, Development, and Evaluations (GRADE) informed 
methodology to analyze data, and resulted in strong or conditional recommendations, 
or a decision that no recommendation could be made.&nbsp;<sup>1</sup></p>&nbsp;</div>
    </Rodal>
  );
};
