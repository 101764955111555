import { createSlice } from '@reduxjs/toolkit'




const initialState = {
    isOpen: false,
    zIndex: -100,
    opacity: 0,
}

export const backdropSlice = createSlice({
    name: 'backdrop',
    initialState,
    reducers: {
        openBackdrop: (state) => {
            return {
                isOpen: true,
                zIndex: 100,
                opacity: 0
            }
        },
        closeBackdrop: (state) => {
            return {
                isOpen: false,
                zIndex: -100,
                opacity: 0
            }
        },
        setOpacity: (state, action) => {
            state.opacity = action.payload
        }

    }
})

// const { 
//     toggleDebugMode,
//     toggleIsi,
//     setIsiEntered,
//     setIsiDragging
//    } = coreSlice.actions

//   export { coreSlice }
