import React from "react";
import { useCore } from "../core/";
export const Disclaimer = () => {
  const coreState = useCore();
  const { dr } = coreState.core.brand;
  return (
    <div className='disclaimer disclaimer-text enter-animation'>
      <p>Dr Mease was compensated for his time.
      </p>
      <p>Dr Mease is speaking about the GRAPPA recommendations on behalf of Novartis
        Pharmaceuticals Corporation. The perspectives he is sharing are his own.</p>
    </div>
  );
}
