import React, { useRef, useEffect, useState } from "react";
import { Plus } from "./Plus";
import { motion } from "framer-motion";

export const PlusButtonText = ({ className, text, onClick }) => {
    const [isHovered, setIsHovered] = useState(false);
    const textRef = useRef(null);
   
 
    const handleClick=()=>{
        document.body.style.overflow = "hidden";
        onClick();
    }
  
    return (
        <motion.div 
            className="flex plus-button-row row-reverse enter-animation"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleClick} 
        >
            <div ref={textRef} dangerouslySetInnerHTML={{ __html: text }} className={`text plus-button-row mobile-center flama-book ${className}`} />
            <Plus isHoverParent={isHovered}  />
        </motion.div>
    );
}