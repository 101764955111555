import { createSlice } from '@reduxjs/toolkit'
 
 

const initialState = {
  debugMode: false,
    
}

 export const debugModeSlice = createSlice({
    name: 'debugMode',
    initialState,
    reducers: {
        
        toggleDebugMode: (state) => {
          state.debugMode = !state.debugMode
        },
        
      }
  })
  
// const { 
//     toggleDebugMode,
//     toggleIsi,
//     setIsiEntered,
//     setIsiDragging
//    } = coreSlice.actions

//   export { coreSlice }
