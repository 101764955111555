import React, { useRef, useEffect } from "react";
import gsap, { ScrollTrigger } from "gsap/all";
// import { TeriaryTitle } from "./TertiaryTitle";
// import { PrimaryTitle } from "./PrimaryTitle";
// import { SecondaryTitle } from "./SecondaryTitle";
gsap.registerPlugin(ScrollTrigger);
const PrimaryTitle = ({ children, className }) => <div className={`title primary-title enter-animation ${className}`}>{children}</div>
const SecondaryTitle = ({ children, className }) => <div className={`secondary-title enter-animation ${className}`}>{children}</div>
const TertiaryTitle = ({ children, className }) => <div className={`title tertiary-title enter-animation ${className}`}>{children}</div>
const Title = ({ children }) => {
    const containerRef = useRef(null);


   

    return (<div className={`titles`} ref={containerRef}>
        {children}
    </div>
    )
}
Title.PrimaryTitle = PrimaryTitle;
Title.SecondaryTitle = SecondaryTitle;
Title.TertiaryTitle = TertiaryTitle;
export { Title }