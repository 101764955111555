import gsap, { GSDevTools } from 'gsap/all';
import { useLayoutEffect , useRef} from 'react';

gsap.registerPlugin(GSDevTools);

export const GSD = ({ debugMode}) => {
    const gsdRef = useRef(null);
    useLayoutEffect(() => {
    GSDevTools.create({
        container: gsdRef.current,
        visibility: debugMode ? 'visible' : 'hidden',
    });
 
    }, [debugMode]);

    return (
        <div style={{zIndex: 9999999999}} ref={gsdRef} />
    );
    }