import { useCore } from "../../core";
import { useParams } from "react-router-dom";
import { Hotspot, HotspotButton } from '../Hotspot';
import { Woman, TestSpot } from '../../components';
import { useEffect, useState } from "react";
import { useResponsive } from "ahooks";

import gsap from 'gsap'

export const HotspotGrid = ({ pageName }) => {
  const responsive = useResponsive();
  const { videoID } = useParams();
  const [height, setHeight] = useState(0);

  const coreState = useCore();
  const { hotspots } = coreState;
  const visibleHotspots = !videoID ? hotspots.hotspots : [hotspots.hotspots.find(hotspot => hotspot.id === videoID)]

  const getVideoHeight = () => {
    const heightTarget = document.getElementById('woman-height-target');
    return heightTarget.getBoundingClientRect().height + 0;
  }


  useEffect(() => {
    const calcHeight = () => {
      const newHeight = getVideoHeight()
      if (newHeight !== height) {
        setHeight(newHeight);
      }
    }
    window.addEventListener('resize', calcHeight)

    return () => {
      window.removeEventListener('resize', calcHeight)
    }
  }, [height])



  useEffect(() => {
    const videoHeight = getVideoHeight();
    if (height === 0) {
      setHeight(videoHeight);
    }
    gsap.to('.hotspot-text, .hotspot-icon', { scale: 1, opacity: 1, delay: 1, stagger: .05, duration: .15, ease: "expo.out" })
  }, [height])

  const variants = {
    initial: {
      opacity: 0,
      z: 600,
      scale: 1.5,
    },
    animate: {
      opacity: 1,
      z: 0,
      scale: 1,
      transition: {
        duration: .3,
        ease: 'easeInOut',
        staggerChildren: .1,
        delayChildren: .7
      },
    },
    exit: {
      opacity: 0,
      z: 600,
      scale: 1.5,
      transition: {
        duration: .3,
        ease: 'easeInOut',
        staggerChildren: .3,
        when: 'afterChildren',
        delayChildren: .3
      }

    }
  }

  return (

    <div
      className="hotspot-grid"
      variants={variants}
      initial="initial"
      animate="animate"
      exit="exit"
      //style={{ height: height }}
    >
      <Woman />

      {visibleHotspots.map((hotspot) => {
        const icon = hotspot.id === 'grappa-overview' ? "grey" : "pink";

        if (window.innerWidth <= 1100) {
         return <HotspotButton key={hotspot.id} hotspot={hotspot} icon={icon} />

        } else {
         return <Hotspot key={hotspot.id} hotspot={hotspot} icon={icon} pageName={pageName} />

        }
      })}
    </div>

  );
}