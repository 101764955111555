import React, { useEffect, useRef, useState, forwardRef } from "react";
import { useCore } from "../../core";
import { motion, useAnimationControls } from "framer-motion";

export const HotspotIcon = forwardRef((props, ref) => {
    const [isHovered, setIsHovered] = useState(false);
    const coreState = useCore();
    const { assets } = coreState;


    let iconSrc = props.icon === "pink" ? assets.PinkHotspot.url : assets.GreyHotspot.url;
    let icon = props.icon === "pink" ? "pink" : "grey";
    if(props.hotspot.id === "axial-symptoms") {
        iconSrc = assets.LineHotspot.url;
        icon = "wide-line";
    };

    let pageName = 'video';
    if (props.pageName === 'home') {
        pageName = 'home';
    }

    let whileHover = { scale: 1.1 };
    let whileTap = { scale: 0.9 };
    if (props.noAnimation) {
        whileHover = {};
        whileTap = {};
    }

    return (
        <motion.img
            ref={ref}
            whileHover={whileHover}
            whileTap={whileTap}
            className={`hotspot-icon hotspot-icon-${icon} ${pageName} ${props.isHovered && 'hovered'} ${props.pulse && 'pulse'} ${props.inline && 'inline'} ${props.className}`}
            onClick={props.onClick}
            onMouseEnter={() => {
                if (props.onMouseEnterFN)
                    props.onMouseEnterFN();
                setIsHovered(true);
            }}
            onMouseLeave={() => {
                if (props.onMouseLeaveFN)
                    props.onMouseLeaveFN();
                setIsHovered(false);
            }}



            src={iconSrc}
            alt=""
        />








    );
});