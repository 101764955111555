import React, { useRef, useEffect } from "react";
import { useCore } from "../../core";
import { useNavigate, useParams } from "react-router-dom";
import woman from '../../assets/img/woman.svg';

export const Woman2dImage = () => {
  const coreState = useCore()
  // const [height , setHeight] = React.useState(0);
  const { assets, hotspots, core } = coreState;
  const { videoID } = useParams();

  const navigate = useNavigate();

 

  const { brand } = core;

  // useEffect(() => {
  //   const getVideoHeight = () => {
  //     return document.getElementById('video').getBoundingClientRect().height;
  //   }
  //   const videoHeight = getVideoHeight();
  //   if(height === 0) {
  //     setHeight(videoHeight);
  //   }
  // }, [height]);
  // udebuseEffect(() => {
  // console.log('BaseWoman.img', BaseWoman.img)
  // womanRef.current.innerHTML = BaseWoman.img.toString();
  // }, [BaseWoman]);
  // useEffect(() => {
    // const hotspots = document.querySelectorAll('.hotspot');
    // if (hotspots.length > 0)
      // setTimeout(() => {
      //   gsap.fromTo(".hotspot", { opacity: 1, z: 550 }, { z: 0, opacity: 1, stagger: 0.1, duration: .3, ease: 'circ.out' });
      // }, 500);
    // gsap.fromTo(hotspot, { opacity: 1, z: 500, perspective: 550 }, { opacity: 1, z: 0, stagger: 0.05, delay: 2, duration: 1.5, ease: 'power2.out' });
  // }, [hotspots]);

  return (


      <img className="woman-target woman hide-breakpoint-1" src={`${woman}`} alt={brand.CLIENT} />

      


  );
};