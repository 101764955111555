import React from "react";
import { useCore } from "../core/";
import curve from '../assets/img/curve.png';
export const Background = () => {
    const { assets } = useCore()
    return (
        <>
            <img src={curve} className="new-curve" alt="" />
            <div className='curve-bg-extra' />
        </>
    );
}