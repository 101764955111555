import React, { useMemo } from 'react'
import gsap from 'gsap'
import { createSlice } from '@reduxjs/toolkit'

const isiStateTypes = {
    CLOSED: "CLOSED",
    OPEN: "OPEN",
}
 
const initialState = {
    trialModalIsOpen: false,
    guideLineModalIsOpen: false,
 
    lockBody: false,
    scroll: {
        x: 0,
        y: 0
    },
}

export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
       
        toggleGuidelineModal: (state, action) => { 
            state.guideLineModalIsOpen = !state.guideLineModalIsOpen
        },
        toggleTrialModal: (state, action) => { 
            state.trialModalIsOpen = !state.trialModalIsOpen
        },
        setUiLoaded: (state, action) => {
            state.uiLoaded = true
        },
      
     
        lockBody: (state, action) => {debugger
            state.lockBody = action.payload
        },
        unlockBodyScroll: (state) => {
            state.lockBody = false
        },

        setScrollX: (state, action) => {
            state.scroll.x = action.payload
        },
        setScrollY: (state, action) => {
            state.scroll.y = action.payload
        },
       
    }
})

export const { toggleGuidelineModal, toggleTrialModal, setUiLoaded, lockBody, unlockBodyScroll, setScrollX, setScrollY } = uiSlice.actions