import  { useRef,useCallback, useEffect } from "react";

export const useLockBodyScroll = () => {
    const locked = useRef(false);
    const lockedBy = useRef(null);
    const lock = useCallback(() => {
        if (!locked.current) {
            locked.current = true;
            lockedBy.current = "useLockBodyScroll";
            document.body.style.overflow = 'hidden';
        }
    }, []);
    const unlock = useCallback(() => {
        if (locked.current && lockedBy.current === "useLockBodyScroll") {
            locked.current = false;
            lockedBy.current = null;
            document.body.style.overflow = 'auto';
        }
    }, []);
    useEffect(() => {
        return () => {
            unlock();
        }
    }, []);
    return [locked.current, lock, unlock];
}