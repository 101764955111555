import React, { useRef } from 'react';
import * as THREE from 'three';
import gsap from 'gsap';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import wm from '../../assets/models/female.glb'

let canvasAdded = false;
export const Woman3dModel = () => {
    const canvasRef = useRef(null);
    const sceneRef = useRef(null);
    const cameraRef = useRef(null);
    const rendererRef = useRef(null);
    const modelRef = useRef(null);
    const ambientLightRef = useRef(null);
    const axesHelperRef = useRef(null);

    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
    const loader = new GLTFLoader();
    loader.setDRACOLoader(dracoLoader);


    axesHelperRef.current = new THREE.AxesHelper(500);
    sceneRef.current = new THREE.Scene();
    cameraRef.current = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1000);
    ambientLightRef.current = new THREE.AmbientLight(0xffffff, .1);
    sceneRef.current.add(
        cameraRef.current,
        axesHelperRef.current,
        ambientLightRef.current
    );

    rendererRef.current = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true,
    });
    rendererRef.current.setSize(600, 800);
    rendererRef.current.setPixelRatio(window.devicePixelRatio);
    rendererRef.current.domElement.classList.add("canvas", 'woman-canvas')

     




        if (!canvasAdded) {
            canvasRef.current.appendChild(rendererRef.current.domElement);
            canvasAdded = true;
        }

        loader.load(wm, (gltf) => {
            modelRef.current = gltf.scene;
            modelRef.current.children[0].rotation.z = Math.PI / .2;
            const s = 1
            modelRef.current.children[0].scale.set(s, s, s)
            modelRef.current.children[0].position.set(0, -1, 0)
            modelRef.current.children[0].material = new THREE.MeshPhongMaterial({
                color: 0x555555,
                flatShading: true,
                shininess: .3,
                specular: 0x888888,
                emissive: 0xFFFFFF,
                transparent: true,
                opacity: .5,
            });
            sceneRef.current.add(modelRef.current);
        });

        const animate = () => {
            rendererRef.current.render(sceneRef.current, cameraRef.current);
        }
        const controls = new OrbitControls(cameraRef.current);

        controls.enableDamping = true;


        cameraRef.current.position.set(0, 5.6, 8)
        cameraRef.current.lookAt(0, 0, 0)
        gsap.ticker.add(animate)
        // return () => {
        //     gsap.ticker.remove(animate)
        //     renderer.dispose();
        // }
   


    return (
        <div className="canvas woman-canvas" ref={canvasRef} />
    )
}