

import { forwardRef } from "react";
import { useResponsive } from "../../../hooks";
import { DesktopHeaderContent } from "./DesktopHeaderContent";
import { MobileHeaderContent } from './MobileHeaderContent';
import { IsiClickHere } from "../IsiClickHere";

export const IsiHeader = forwardRef((props, ref) => {

    const responsive = useResponsive();
    const {
        isOpen,
        bottomIsiInView,
    } = props;
    

    return (
        <div
            ref={ref}
            className={`isi-header ${props.isOpen ? "open" : "closed"}`}
            style={{ background: false ? 'red' : 'white' }}
        >


                <p className="isi-subtitle isi-enter-animation" style={{ marginTop: 0 }}>
                    IMPORTANT SAFETY INFORMATION
                </p>

            <div className={`flex columns-wrapper ${props.isOpen && "reverse-columns"}`}>

                <div className="col">
                    <p className={`isi-subtitle isi-enter-animation`}>CONTRAINDICATIONS</p>
                    <p className="isi-enter-animation">
                        COSENTYX is contraindicated in patients with a previous serious
                        hypersensitivity reaction to secukinumab or to any of the
                        excipients in COSENTYX. Cases of anaphylaxis have been reported
                        during treatment with COSENTYX.
                    </p>
                </div>
                {responsive.lg && <div className="col">
                    <p className="isi-subtitle isi-enter-animation">INDICATIONS</p>
                    <p className="isi-enter-animation">
                        COSENTYX<sup>&reg;</sup> (secukinumab) is indicated for the
                        treatment of moderate to severe plaque psoriasis in patients 6
                        years and older who are candidates for systemic therapy or
                        phototherapy.
                    </p>
                </div>}
            </div>

            {!props.bottomIsiInView && (
            <IsiClickHere isOpen={props.isOpen} />
            )}
        </ div>
    );
});
