import { createSlice } from '@reduxjs/toolkit'
const initialState =  {
    x: 0,
    y: 0,
    vx: 0,
    vy: 0,
    lx: 0,
    ly: 0,
    dx: 0,
    dy: 0,
    lockX: 0,
    lockY: 0,
    scaleX: 0,
    scaleY: 0,
    rotX: 0,
    rotY: 0,
    magneticX: 0,
    magneticY: 0,
    stickX: 0,
    stickY: 0,
    isLocked: false,
    isStuck: false,
    isMagnetic: false,
    lockElement: null,
    stuckElement: null,
    magnetElement: null,
    lockElements: [],
    stuckElements: [],
    magnetElements: [],
    isMoving: false,
    isHovering: false,
    isDragging: false,
    isDown: false,
    visible: false,
    timeout: null,
    timeoutDelay: 1000,
}

const mouseSlice = createSlice({
    name: 'mouse',
    initialState,
    reducers: {
        setPosition: (state, action) => {
            state.x = action.payload.x
            state.y = action.payload.y
        },
        setVelocity: (state, action) => { 
            state.vx = action.payload.vx
            state.vy = action.payload.vy
        },
        setPrevPosition: (state, action) => {
            state.lx = action.payload.lx
            state.ly = action.payload.ly
        },
        setDelta: (state, action) => {
            state.dx = action.payload.dx
            state.dy = action.payload.dy
        },
        setLockPosition: (state, action) => {
            state.lockX = action.payload.lockX
            state.lockY = action.payload.lockY
        },
        setScale: (state, action) => {
            state.scaleX = action.payload.scaleX
            state.scaleY = action.payload.scaleY
        },
        setRotation: (state, action) => {
            state.rotX = action.payload.rotX
            state.rotY = action.payload.rotY
        },
        setMagneticPosition: (state, action) => {
            state.magneticX = action.payload.magneticX
            state.magneticY = action.payload.magneticY
        },
        setStickPosition: (state, action) => {
            state.stickX = action.payload.stickX
            state.stickY = action.payload.stickY
        },
        setIsLocked: (state, action) => {
            state.isLocked = action.payload
        },
        setIsStuck: (state, action) => {
            state.isStuck = action.payload
        },
        setIsMagnetic: (state, action) => {
            state.isMagnetic = action.payload
        },
        setLockElement: (state, action) => {
            state.lockElement = action.payload
        },
        setStuckElement: (state, action) => {
            state.stuckElement = action.payload
        },
        setMagnetElement: (state, action) => {
            state.magnetElement = action.payload
        },
        setLockElements: (state, action) => {
            state.lockElements = action.payload
        },
        setStuckElements: (state, action) => {
            state.stuckElements = action.payload
        },
        setMagnetElements: (state, action) => {
            state.magnetElements = action.payload
        },
        setIsMoving: (state, action) => {
            state.isMoving = action.payload
        },
        setIsHovering: (state, action) => {
            state.isHovering = action.payload
        },
        setIsDragging: (state, action) => {
            state.isDragging = action.payload
        },
        setIsDown: (state, action) => {
            state.isDown = action.payload
        },
        setVisible: (state, action) => {
            state.visible = action.payload
        },
        setTimeout: (state, action) => {
            state.timeout = action.payload
        },  
        setTimeoutDelay: (state, action) => {
            state.timeoutDelay = action.payload
        },
    }

})

// const {
//     setPosition,
//     setVelocity,
//     setPrevPosition,
//     setDelta,
//     setLockPosition,
//     setScale,
//     setRotation,
//     setMagneticPosition,
//     setStickPosition,
//     setIsLocked,
//     setIsStuck,
//     setIsMagnetic,
//     setLockElement,
//     setStuckElement,
//     setMagnetElement,
//     setLockElements,
//     setStuckElements,
//     setMagnetElements,
//     setIsMoving,
//     setIsHovering,
//     setIsDragging,
//     setIsDown,
//     setVisible,
//     setTimeout,
//     setTimeoutDelay,

// } = mouseSlice.actions

export { mouseSlice }

