import { createSlice } from '@reduxjs/toolkit'
import { from } from 'rxjs'
import { map } from 'rxjs/operators'
const spring = {
    type: "spring",
    duration: .3,
};
const initialState = {
    page: {
        initial: {
            y: 200,
            opacity: 0
        },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                when: 'beforeChildren',
                delayChldren: 2,
                staggerCildren: .5
            }
        },
        exit: {
            y: 100,
            opacity: 0,
            transition: {
                when: "afterChildren",
            },
        }
    },
    header: {
        initial: {
            y: -200,
            opacity: 0
        },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                type: 'spring',
                when: 'beforeChildren',
                delayChldren: 5,
                staggerCildren: 5
            },
        },
    },
    logo: {

        home: {
            initial: {
                scale: 0,
                opacity: 0,
                x: -100
            },
            animate: {
                x: 50,
                scale: 1,
                opacity: 1,
                transition: {
                    type: 'spring',
                    when: 'beforeChildren',
                }
            },
            exit: {
                x: -100,
                scale: 0,
                opacity: 0,
                transition: {
                    when: "afterChildren",
                }
            }
        },
        video: {
            initial: {
                scale: 0,
                opacity: 0
            },
            animate: {
                scale: 1,
                opacity: 1,
                transition: {
                    delay: .3,
                    type: 'spring',
                }
            },
            exit: {
                scale: 0,
                opacity: 0
            }
        }

    },
    backArrow: {
        home: {
            initial: {
                opacity: 0,
                x: -200
            },
            animate: {
                x: -200,
                opacity: 0,
                transition: {
                    type: 'spring',
                }
            },
            exit: {
                x: -100,
                opacity: 0,
                transition: {
                    when: "afterChildren",
                }
            }
        },
        video: {
            initial: {
                scale: 0,
                opacity: 0
            },
            animate: {
                scale: 1,
                opacity: 1,
                transition: {
                    type: 'spring',

                }
            },
            exit: {
                scale: 0,
                opacity: 0
            }
        }
    },
    hotspot: {
        initial: {
            opacity: 0,
            z: 500,
            perspective: 550,
            scale: 0
        },
        animate: {
            opacity: 1,
            z: 0,
            scale: 1,
            transition: {
                duration: .1,
            }
        },
        exit: {
            opacity: 0,
            z: 500,
            perspective: 550,
            scale: 0,
            transition: {
                duration: 1.5,
            }
        }
    },
    hovered: {
        whileHover: { scale: 1.1 },
        whileTap: { scale: 0.9 },
        transition: { type: 'spring', stiffness: 300, damping: 20 }
    }
}
export const animationSlice = createSlice({
    name: 'animation',
    initialState,
    reducers: {

        addAnimation: (state, action) => {
            state.animationState = [...state, action.payload]
        },
    }
})