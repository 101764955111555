import { useSelector, useDispatch } from 'react-redux'
import { Observable } from 'rxjs'
import { backdropSlice, animationSlice, coreSlice, debugModeSlice, hotspotsSlice, headerSlice, uiSlice, mouseSlice, isiSlice } from './slices'
import { useMemo } from 'react'
export const useCore = () => {
    const dispatch = useDispatch()
    const coreState = useSelector(state => state)

    const EventEmitter = () => ({
        on(event, callback) {
          document.addEventListener(event, (e) => callback(e.detail));
        },
        dispatch(event, data) {
          document.dispatchEvent(new CustomEvent(event, { detail: data }));
        },
        remove(event, callback) {
          document.removeEventListener(event, callback);
        },
      });
      
      const emitter = EventEmitter();
    const observable = new Observable((subscriber) => {
        subscriber.next(coreState)
    })

    const backdropActions = Object.keys(backdropSlice.actions).reduce((acc, key) => {
        acc[key] = (data) => dispatch(backdropSlice.actions[key](data))
        return acc
    }, {})

    const headerActions = Object.keys(headerSlice.actions).reduce((acc, key) => {
        acc[key] = (data) => dispatch(headerSlice.actions[key](data))
        return acc
    }, {})

    const coreActions = Object.keys(coreSlice.actions).reduce((acc, key) => {
        acc[key] = (data) => dispatch(coreSlice.actions[key](data))
        return acc
    }, {})
    const hotspotActions = Object.keys(hotspotsSlice.actions).reduce((acc, key) => {
        acc[key] = (data) => dispatch(hotspotsSlice.actions[key](data))
        return acc
    }, {})


    const uiActions = Object.keys(uiSlice.actions).reduce((acc, key) => {
        acc[key] = (data) => dispatch(uiSlice.actions[key](data))
        return acc
    }, {})
    const mouseActions = Object.keys(mouseSlice.actions).reduce((acc, key) => {
        acc[key] = (data) => dispatch(mouseSlice.actions[key](data))
        return acc
    }, {})
    const isiActions = Object.keys(isiSlice.actions).reduce((acc, key) => {
        acc[key] = (data) => dispatch(isiSlice.actions[key](data))
        return acc
    }, {})

    const debugModeActions = Object.keys(debugModeSlice.actions).reduce((acc, key) => {
        acc[key] = (data) => dispatch(debugModeSlice.actions[key](data))
        return acc
    }, {})

    const animationActions = Object.keys(animationSlice.actions).reduce((acc, key) => {
        acc[key] = (data) => dispatch(animationSlice.actions[key](data))
        return acc
    }, {})

    const windowWidth = useMemo(() => window.innerWidth, [])
    const windowHeight = useMemo(() => window.innerHeight, [])

    return {

        backdrop: coreState.backdrop,
        core: coreState.core,
        assets: coreState.assets,
        isi: coreState.isi,

        hotspots: coreState.hotspots,
        ui: coreState.ui,
        mouse: coreState.mouse,
        header: coreState.header,
        debugMode: coreState.debugMode,
        animation: coreState.animation,
        windowWidth,
        windowHeight,
        emitter,
        backdropActions,
        coreActions,
        observable,

        uiActions,
        mouseActions,
        isiActions,
        hotspotActions,
        headerActions,
        debugModeActions,
        animationActions,
        dispatch

    }
}
