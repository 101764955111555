import { GuidelineModal, TrialModal,  Background    } from '../components';
import { useCore } from "../core";
export const UI = () => {
    const coreState = useCore();
    return (
        <>
            <Background />
           {/* <ElasticMouseFollower /> */}
        </>
    );
}

