import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    activeHotspot: "home",
    hotspots: [
        //Grappa Overview
        {
            id: "grappa-overview",
            textPosition: 'right',
            pretty: 'GRAPPA Overview',
            landingStyle: {
                top: '15%',
                left: '65%',
                width: 158
            },
            landingStyleVideo: {
                top: '12%',
                left: '65%',
                width: 158
            },
            pill: {
                id: 0,
                title: 'GRAPPA Overview',
                img: 'GreyHotspot'
            },
            video: {
                src: "https://d59nx84ake9tn.cloudfront.net/grappa-guidelines/videos/Vid+1.mp4",
                posterSrc: "https://d59nx84ake9tn.cloudfront.net/grappa-guidelines/videos/posters/v2/GRAPPA+1.png",
                comment: `<p>Dr Mease provides an overview of the approach GRAPPA has taken to develop and
                    update recommendations for the treatment of PsA.</p>`,
                img: 'smallAllClinicalDomains',
                tertiaryTitle: `<div>Updated GRAPPA 2021 Guidelines include a strong recommendation for the use of</div>`,
                primaryTitle: `<div>IL-17 inhibitors as a first-line biologic</div>`,
                secondaryTitle: `<div>across all <span>6</span> key clinical domains of PsA<sup>1</sup></div>`
            }
        },
        // peripheral arthritis
        {
            id: 'peripheral-arthritis',
            textPosition: 'left',
            pretty: 'Peripheral Arthritis',
            landingStyle: {
                top: '20%',
                left: '18%',
                width: '31%'
            },
            landingStyleVideo: {
                top: '17%',
                right: '56%',
                width: '31%'
            },
            pill: {
                id: 1,
                title: 'Peripheral Arthritis',
                img: 'pillPeripheralArthritis'
            },
            video: {
                src: "https://d59nx84ake9tn.cloudfront.net/grappa-guidelines/videos/Vid+2.mp4",
                posterSrc: "https://d59nx84ake9tn.cloudfront.net/grappa-guidelines/videos/posters/v2/GRAPPA+2.png",
                comment: `<p>Dr Mease discusses arthritis affecting peripheral joints in arms and legs, a key clinical
                    manifestation in patients with PsA.</p>`,
                tertiaryTitle: "",
                primaryTitle: `<div>IL-17 inhibitors, such as COSENTYX, are strongly recommended by GRAPPA</div>`,
                secondaryTitle: `<div>for results in all key clinical manifestations of PsA*</div>`,

                img: 'smallPeripheralArthritis'
            }
        },
        // //axial symptoms
        {
            id: 'axial-symptoms',
            textPosition: 'left',
            pretty: 'Axial Symptoms',
            landingStyle: {
                top: '38%',
                left: '6%',
                width: '45%'
            },
            landingStyleVideo: {
                top: '35%',
                left: '6%',
                width: '45%'
            },
            pill: {
                id: 2,
                title: 'Axial Symptoms',
                img: 'pillAxialSymptoms'
            },
            video: {
                src: "https://d59nx84ake9tn.cloudfront.net/grappa-guidelines/videos/Vid+3.mp4",
                posterSrc: "https://d59nx84ake9tn.cloudfront.net/grappa-guidelines/videos/posters/v2/GRAPPA+3.png",
                comment: `<p>Dr Mease discusses PsA pain and stiffness in the axial joints of the spine and
                    sacroiliac joints.</p>`,
                tertiaryTitle:  "",
                primaryTitle:  "IL-17 inhibitors, such as COSENTYX, are strongly recommended by GRAPPA",
                secondaryTitle: "for results in all key clinical manifestations of PsA*",

                img: 'smallAxialSymptoms'
            }
        },
        // //enthsitis
        {
            id: 'enthesitis',
            pretty: 'Enthesitis',
            textPosition: 'left',
            landingStyle: {
                top: '85%',
                left: '20%',
                width: '29%'
            },
            landingStyleVideo: {
                top: '88%',
                left: '17%',
                width: '29%'
            },
            pill: {
                id: 3,
                title: 'Enthesitis',
                img: 'pillEnthesitis'
            },
            video: {
                src: "https://d59nx84ake9tn.cloudfront.net/grappa-guidelines/videos/Vid+4.mp4",
                posterSrc: "https://d59nx84ake9tn.cloudfront.net/grappa-guidelines/videos/posters/v2/GRAPPA+4.png",
                comment: `<p>Dr Mease discusses enthesitis, or inflammation at the sites where tendons and ligaments
                    insert into bone, a key clinical manifestation in patients with PsA.</p>`,
                tertiaryTitle: "",
                primaryTitle: `<div>IL-17 inhibitors, such as COSENTYX, are strongly recommended by GRAPPA</div>`,
                secondaryTitle: `<div>for results in all key clinical manifestations of PsA*</div>`,


                img: 'smallEnthesitis'
            }
        },
        // //dactylitis
        {
            id: 'dactylitis',
            textPosition: 'right',
            pretty: 'dactylitis',
            landingStyle: {
                top: '85%',
                left: '57%',
            },
            landingStyleVideo: {
                top: '90%',
                left: '56%',
            },
            pill: {
                id: 4,
                title: 'Dactylitis',
                img: 'pillDactylitis'
            },
            video: {
                src: "https://d59nx84ake9tn.cloudfront.net/grappa-guidelines/videos/Vid+5.mp4",
                posterSrc: "https://d59nx84ake9tn.cloudfront.net/grappa-guidelines/videos/posters/v2/GRAPPA+5.png",
                comment: `<p>Dr Mease discusses dactylitis, the diffuse painful swelling of tendons or joints in a digit,
                    sometimes referred to as “sausage digits,” a key clinical manifestation in patients with PsA.</p>`,
                tertiaryTitle: "",
                primaryTitle: `<div>IL-17 inhibitors, such as COSENTYX, are strongly recommended by GRAPPA</div>`,
                secondaryTitle: `<div>for results in all key clinical manifestations of PsA*</div>`,
                img: "smallDactylitis"
            }
        },
        // // Plaque Psoriasis
        {
            id: 'plaque-psoriasis',
            textPosition: 'right',
            pretty: 'Plaque Psoriasis',
            landingStyle: {
                top: '70%',
                left: '52%',
                width: 163
            },
            pill: {
                id: 5,
                title: 'Plaque Psoriasis',
                img: 'pillPlaquePsoriasis'
            },
            video: {
                src: "https://d59nx84ake9tn.cloudfront.net/grappa-guidelines/videos/Vid+6.mp4",
                posterSrc: "https://d59nx84ake9tn.cloudfront.net/grappa-guidelines/videos/posters/v2/GRAPPA+6.png",
                comment: `<p>Dr Mease discusses plaque psoriasis, a key clinical manifestation in patients with PsA
                    characterized by rash with itchy, scaly patches, often on the knees, elbows, and trunk.</p>`,
                tertiaryTitle: "",
                primaryTitle: `<div>IL-17 inhibitors, such as COSENTYX, are strongly recommended by GRAPPA</div>`,
                secondaryTitle: `<div>for results in all key clinical manifestations of PsA*</div>`,


                img: 'smallPlaquePsoriasis'
            }
        },
        // //nail psoriasis
        {
            id: 'nail-psoriasis',
            textPosition: 'bottom',
            pretty: 'Nail Psoriasis',
            landingStyle: {
                top: '46%',
                left: '58%',
                height: 91,
                width: '20%'
            },
            landingStyleVideo: {
                top: '46%',
                left: '58%',
                height: 91,
                width: '20%'
            },
            pill: {
                id: 6,
                title: 'Nail Psoriasis',
                img: 'pillNailPsoriasis'
            },
            video: {
                src: "https://d59nx84ake9tn.cloudfront.net/grappa-guidelines/videos/Vid+7.mp4",
                posterSrc: "https://d59nx84ake9tn.cloudfront.net/grappa-guidelines/videos/posters/v2/GRAPPA+7.png",
                tertiaryTitle: "",
                primaryTitle: `<div className='primary-title'>IL-17 inhibitors, such as COSENTYX, are strongly recommended by GRAPPA</div>`,
                secondaryTitle: `<div className='secondaryTitle'>for results in all key clinical manifestations of PsA*</div>`,
                comment: `<p>Dr Mease discusses nail psoriasis, a key clinical manifestation in patients with PsA characterized by pitting, discoloration, and changes in the structure of the nails.</p>`,
                img: 'smallNailPsoriasis'
            }
        },

    ]
}

export const hotspotsSlice = createSlice({
    name: 'hotspots',
    initialState,
    reducers: {
        setActiveHotspot: (state, action) => {
            state.activeHotspot = action.payload
        }
    }
})

