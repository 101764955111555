import { createSlice } from '@reduxjs/toolkit'
import { from } from 'rxjs'
import { map } from 'rxjs/operators'

const initialState = {
    isOpen: false,
    state: "initial",
    hasEntered: false,
    deltaY: 0,
    direction: undefined,
    bounds: {
        top: undefined,
        bottom: undefined,
        right: undefined,
        left: undefined,
    },
    isDragging: false,
    height: 0,
   headerHeight: 0,
    contentHeight: 0,
    dragY: 0,
}

export const isiSlice = createSlice({
    name: 'isi',
    initialState,
    reducers: {
        
        setOpen: (state, action) => {
            state.isOpen = action.payload
        },
        toggle: (state) => {
            state.isOpen = !state.isOpen
        },
        setEntered: (state, action) => {
            state.hasEntered = true
        },
        setHeaderHeight: (state, action) => {
            state.headerHeight = action.payload
        },
        setContentHeight: (state, action) => {
            state.contentHeight = action.payload
        },
        setHeight: (state, action) => {
            state.height = action.payload
        },
        setDeltaY: (state, action) => {
            state.deltaY = action.payload
        },
        setBounds: (state, action) => {
            state.bounds.top = action.payload.top || undefined;
            state.bounds.bottom = action.payload.bottom || undefined;
            state.bounds.right = action.payload.right || undefined;
            state.bounds.left = action.payload.left || undefined;
        },
        setDragging: (state, action) => {
            state.isDragging = action.payload
        },
        setDragY: (state, action) => {
            state.dragY = action.payload
        },
        setDirection: (state, action) => {
            state.direction = action.payload
        },
        
    }
})

 