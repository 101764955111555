import gsap, { SplitText } from 'gsap/all'
import { Spacer, ClickHere, HotspotGrid, Disclaimer, Page, Headshot, PlusButtonText, Title } from '../../components/';
import { useCore } from '../../core';
import { motion } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux'

import { toggleTrialModal, toggleGuidelineModal } from '../../core/slices/ui';

gsap.registerPlugin(SplitText)
export const HomePage = (props) => {

  const dispatch = useDispatch()

  /***************************
   * SET TUITLE
   ****************************/
  const containerRef = useRef(null)
  const coreState = useCore();
  const {    assets } = coreState
  const { Divider } = assets;

  const colVariants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: .3,
        ease: 'easeInOut',
        staggerChildren: .27,
        // delayChildren: .7
      }
    },
    exit: {
      opacity: 0,
      y: -100,
      transition: {
        duration: .3,
        ease: 'easeInOut',
        staggerChildren: .3,
        delayChildren: .3
      }
    }
  }
  const titleVariants = {
    initial: {
      opacity: 0,

    },
    animate: {
      opacity: 1,

      transition: {
        duration: .3,
        ease: 'easeInOut',
        staggerChildren: .27,
        // delayChildren: .7
      }
    },
    exit: {
      opacity: 0,

      transition: {
        duration: .3,

        ease: 'easeInOut',
        staggerChildren: .3,

      }
    }
  }

  useEffect(() => {
    gsap.set(".enter-animation", { opacity: 0, y: 100 })
    gsap.to(".enter-animation", { opacity: 1, y: 0, duration: .3, delay: .3, ease: 'easeInOut', stagger: .05 })
  }, [])

  return (
    <Page pageName="home">
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center' }}>
        <motion.section
          ref={containerRef}
          className="row full"
          variants={colVariants}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <motion.aside
            variants={colVariants}
            id="woman-height-target"
            className="col middle flex text-right-desktop push-right-desktop main content-col">
            <Title
              className="home-title"
              variants={titleVariants}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <Title.TertiaryTitle variants={titleVariants}>
                Updated GRAPPA 2021 Guidelines include a strong recommendation for the use of
              </Title.TertiaryTitle>
              <Title.PrimaryTitle>
                IL-17 inhibitors as a first-line biologic
              </Title.PrimaryTitle>
              <Title.SecondaryTitle variants={titleVariants}>
                across all <span className='pink'>6</span> key clinical domains of&nbsp;PsA<sup className="homepage-sup">1</sup>
              </Title.SecondaryTitle>
            </Title>
            <Headshot />
            <Spacer />
            <Disclaimer />
            <PlusButtonText
              className="guide-toggler"
              onClick={() => dispatch(toggleTrialModal())}
              text={'Information on Updated GRAPPA&nbsp;2021&nbsp;Guidelines.'}
            />
            <PlusButtonText className="trial-toggler long" onClick={() => dispatch(toggleGuidelineModal())} text={"COSENTYX primary end points and study designs supporting the efficacy and results for the 6 key clinical domains."} />
            <ClickHere className="mobile" />
          </motion.aside>
          <motion.aside
            variants={colVariants}
            className="col center flex desktop main divider-col">
            <img src={Divider.url} className="divider " alt="" />
          </motion.aside>
          <motion.aside
            variants={colVariants}
            className='flex col hotspot-parent center hotspot-col' style={{ position: 'relative', transformStyle: 'preserve-3d', }}>
            <HotspotGrid pageName="home" />
          </motion.aside>
        </motion.section>
        <ClickHere className='desktop' />
      </div>
    </Page >
  );
};


