import React, { useEffect, useRef, useState, useMemo } from 'react';
import { BackArrow } from './BackArrow';
import { motion } from 'framer-motion';
import { Logo } from './Logo';
import gsap, { ScrollTrigger } from 'gsap/all';
import { useCore } from '../core';

gsap.registerPlugin(ScrollTrigger);

export const Header = ({ pageName }) => {
  const coreState = useCore();
  const { animation } = coreState;
  const { logo, backArrow } = animation;

  useEffect(() => {
    // ScrollTrigger.create({
    //   trigger: '.header',
    //   start: 'top top',
    //   end: "max",
    //   // markers: true,
    //   pin: true,
    //   pinSpacing: false
    // })

    // return () => {
    //   ScrollTrigger.getAll().forEach(t => t.kill());
    // }
  }, [])
  return (
    <motion.header
      className="header"
    >

      <BackArrow
        pageName={pageName}
        animation={backArrow}
      />

      <Logo
        animation={logo}
        pageName={pageName}
      />
    </motion.header>
  );
};
