import React, { useState } from "react";
import { useCore } from "../core/";
import { HoverScale } from '../components';
import { motion, useAnimate } from "framer-motion";

export const Plus = ({ isHoverParent, onClick }) => {
    const [isHovered, setIsHovered] = useState(false);

    const coreState = useCore();
    const { assets } = coreState;
    const { Plus } = assets;
    function handleMouseEnter() {
        setIsHovered(true);
    }

    function handleMouseLeave() {
        setIsHovered(false);
    }

    const variants = {
        initial: {
            scale: 1
        },
        animate: {
            scale: 1.1,
        }
    }

    return (
        <HoverScale style={{marginLeft: 'auto'}}>
            <div className="plus-wrapper" onClick={onClick}>
                <motion.img
                    onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
                    variants={variants}
                    onClick={onClick}
                    animate={isHovered || isHoverParent ? "animate" : "initial"}
                    src={Plus.url} alt="" className="plus" />
            </div>
        </HoverScale>
    );
}