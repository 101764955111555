import React, { useEffect } from "react";
import { useTitle } from 'ahooks'
import { Isi, UI, Header } from '../../components';
import gsap, { ScrollTrigger, ScrollSmoother, Draggable } from "gsap/all";
import { motion } from "framer-motion";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother, Draggable);

export const Page = ({ children, pageName }) => {
    useTitle(`Cosentyx GRAPPA`)
    useEffect(() => {
        document.body.classList.add(pageName);
    }, [pageName])

    // useEffect(() => {
    //    const intro =  setTimeout(() => {
    //         navigate('/')
    //         clearTimeout(intro)
    //     }, 2000)
    // }, [])

    const className = typeof pageName !== 'undefined' ? `page page-${pageName}` : "page";

    const Tag = pageName === 'splash' ? motion.div : 'div';
    const variants = {
        initial: {
            opacity: 0,
        },
        animate: {
            opacity: 1,
            transition: {
                duration: .3,
                type: 'spring',
                bounce: 0.25,
                staggerChildren: .27,
                delayChildren: .7
            }
        },
        exit: {
            opacity: 0,
            y: -100,
            transition: {
                duration: .3,
                ease: 'easeInOut',
                staggerChildren: .3,
                when: 'afterChildren',
                delayChildren: .3
            },
        }
    }
    return (
        <Tag
            className={className}
            variants={variants}
            initial="initial"
            animate="animate"
            exit="exit"
        >
            <Header pageName={pageName} />
            {children}
            <Isi pageName={pageName} />
            <UI />
        </Tag>
    );
};
