
import React from 'react';
import { Provider } from 'react-redux'
import { store } from './core/store';
import { Debugger, Isi } from './components';
import './assets/styles/style.scss';
import { CustomRouter } from './components';
import { TrialModal, GuidelineModal } from './components/modal';
import { InView } from 'react-intersection-observer';
function App(props) {
  const [bottomIsiInView, setBottomIsiInView] = React.useState(false);

  // useLayoutEffect(() => {
  //   const root = document.getElementById('root');
  //   if (root !== null) {
  //     const children = root.childNodes;
  //     root.replaceWith(...children)
  //   }
  // }, []);

  
  const bottomISIInView = (inView, entry) => {
    setBottomIsiInView(inView);
    const innerIsi = document.querySelector('.page .isi');
    const outerIsi = document.querySelector('.isi.bottom');
    if (innerIsi !== null && outerIsi !== null) {
      console.log('isi style')
      innerIsi.style.display = inView ? 'none' : 'block';
      outerIsi.style.zIndex = inView ? '' : '3';
      outerIsi.style.boxShadow = inView ? '0 0 10px rgba(0, 0, 0, 0.5)' : 'unset';
    }
  }

  
  
   

  return (
    <Provider store={store}>
      <CustomRouter />
      <Debugger />
      <InView as="div" onChange={bottomISIInView} threshold={.15}>
        <Isi bottom bottomIsiInView={bottomIsiInView} />
      </InView>
      <TrialModal />
      <GuidelineModal />
    </Provider>
  );
}

export default App;
