import { useCore } from "../../core";
import { Woman2dImage } from "./Woman2dImage.js";
import { Woman3dModel } from "./Woman3dModel.js";

export const Woman = (props) => {
    const  coreState = useCore();
    const { use3d } = coreState.core;

    const Element = use3d  === true ? <Woman3dModel {...props}  /> : <Woman2dImage {...props} />;
    return Element;
};