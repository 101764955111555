import { useParams, useNavigate } from 'react-router-dom';
import { HotspotGrid, Page, HTML } from '../../components'
import { motion } from 'framer-motion';
import { useCore } from '../../core';
import { useRef } from 'react';
import GrappaVideo from '../GrappaVideo';

export const VideoPage = ({ hotSpot, pageName }) => {
  const navigate = useNavigate();
  const { videoID } = useParams();
  const coreState = useCore();
  const { assets, hotspots } = coreState;
  const videoRef = useRef();
  const hotspot = hotspots.hotspots.find((hotspot) => hotspot.id === videoID);

  const bottomVideoText = () => <>
    <p><small>Dr Mease was compensated for his time.</small></p>
    <p>Dr Mease is speaking about the GRAPPA recommendations on behalf of Novartis
      Pharmaceuticals Corporation. The perspectives he is sharing are his own.</p>
    <p>*Based on the 2021 GRAPPA Treatment Guidelines, which recommend considering all key
      clinical domains of PsA disease when choosing a treatment.<sup>1</sup></p></>

  let womenTransform = '';
  if (hotspot.id === 'grappa-overview') {
    womenTransform = 'translateX(-30px)';
  }
  console.log('hotsport', hotspot);

  return (
    <>
      <Page pageName="video">
        <div className="video-page-inner-wrapper">
          <section className='row title-row'>
            <div className="video-title">
              <div className="tertiary-title text-left-desktop text-left-mobile">
                <HTML jsx={hotspot.video.tertiaryTitle} />
              </div>
              <div className="primary-title text-left-desktop text-left-mobile">
                <HTML jsx={hotspot.video.primaryTitle} />
              </div>
              <div className="secondary-title text-left-desktop text-left-mobile">
                <HTML jsx={hotspot.video.secondaryTitle} />
              </div>
            </div>
          </section>
           <section className='row content-row'>

            <aside className="col flex left offset-2">
              {/* <video id="woman-height-target" ref={videoRef} poster={hotspot.video.posterSrc} controls playsInline>
                <source src={hotspot.video.src} type="video/mp4" />
                Your browser does not support the video tag.
              </video> */}
              <GrappaVideo
                id="woman-height-target"
                posterSrc={hotspot.video.posterSrc}
                videoSrc={hotspot.video.src}
              />
            </aside>
            <aside className="col center" style={{ flex: 1, transform: womenTransform }}>
              <HotspotGrid pageName={videoID} />
            </aside>
          </section>
          <section className='row text-row'>

            <div className="video-comment video-text">
              <HTML jsx={hotspot.video.comment} />
              {bottomVideoText()}
            </div>

          </section>
        </div>
      </Page>
    </>
  );
};


