import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { VideoPage, HomePage } from "./pages";
import { configResponsive } from "../hooks/useResponsive.ts";

export const CustomRouter = (props) => {
  const location = useLocation();
  configResponsive({
    mini: 450,
    sm: 600,
    md: 768,
    lg: 905,
    xl: 1100,
    xxl: 1200,
  });


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
 


  return (
      <Routes location={location} key={location.pathname}>
        <Route index path="/" element={<HomePage />} />
        <Route path="/:videoID" element={<VideoPage />} />
        <Route path="*" element={<HomePage />} />
      </Routes>

  );
};


