  /**
   *
   * @param {string} from - color in hex format
   * @param {string} to - color in hex format
   * @returns {PIXI.Texture}
   */
  import { useCanvas } from "./useCanvas";
  import * as PIXI from "pixi.js";

  export const useGradientTexture = (from, to) => {
    const textureWH = 300;
    const canvasRef = useCanvas();
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const gradient = context.createLinearGradient(
        0,
        0,
        textureWH,
        textureWH,
    );
    gradient.addColorStop(0, from);
    gradient.addColorStop(1, to);
    context.fillStyle = gradient;
    debugger
    context.fillRect(0, 0, textureWH, textureWH);
debugger
    return new PIXI.Texture.from(canvas);
}